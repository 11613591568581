import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CircleButton = ({ icon, className, ...rest }) => (
  <button className={`btn btn-circle ${className}`} {...rest}>
    <FontAwesomeIcon icon={icon} />
  </button>
);

export default CircleButton;
