import React, { useState, useContext, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { useParams } from "react-router";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../context/AuthContext";
import GradientButton from "../components/common/GradientButton";
import { publicFetch } from "../utils/fetch";
import Unauthorized from "./Unauthorized";

// TODO: This page must be called only when a user receives a link via email
const ResetPassword = () => {
  const { token } = useParams();
  const authContext = useContext(AuthContext);

  const [resetLoading, setResetLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);
  const [userEmail, setUserEmail] = useState();

  useEffect(() => {
    publicFetch
      .post(`/api/v1/check_reset_token`, { token: token })
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
      .then((data) => {
        setUserEmail(data.email);
      })
      .catch((error) => {
        console.log(error);
        setUnauthorized(true);
      });
  }, []);

  const handleSubmit = (values, { setSubmitting }) => {
    // TODO: do a public fetch to /api/v1/reset endpoint
    setResetLoading(true);
    const data_form = {
      password: values["newPassword"],
      token: token,
    };
    publicFetch
      .post(`api/v1/reset`, data_form)
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
      .then((data) => {
        authContext.setAuthState(data);

        setTimeout(() => {
          setRedirect(true);
        }, 500);
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      })
      .finally(() => {
        setResetLoading(false);
        setSubmitting(false);
      });
  };

  return (
    <>
      {redirect && <Redirect to="/home" />}
      {unauthorized ? (
        <Unauthorized />
      ) : (
        userEmail && (
          <Formik
            initialValues={{ newPassword: "", newPasswordConfirm: "" }}
            validationSchema={Yup.object().shape({
              newPassword: Yup.string()
                .required("Campo richiesto")
                .min(8, "Deve contenere almeno 8 caratteri"),
              newPasswordConfirm: Yup.string().oneOf(
                [Yup.ref("newPassword"), null],
                "Le password devono combaciare"
              ),
            })}
            onSubmit={handleSubmit}
          >
            <Form
              className="container fluid text-center"
              style={{
                width: "25rem",
                marginInline: "auto",
                marginTop: "10rem",
              }}
            >
              <div className="text-center container">
                <img
                  className="img-fluid"
                  src="https://tecnoform.takesover.it/wp-content/uploads/2022/03/Tecnoform-logo2-1-300x62.png"
                  alt="Tecnoform"
                ></img>
                <h3>Superscheduler</h3>
              </div>

              <h5>Reimposta Password utente</h5>
              <h6>{userEmail}</h6>

              <hr />

              <div className="form-group">
                <label className="form-label" htmlFor="newPassword">
                  Nuova Password
                </label>

                <Field
                  className="form-control"
                  name="newPassword"
                  type="password"
                />

                <ErrorMessage
                  name="newPassword"
                  render={(msg) => <span className="text-danger">{msg}</span>}
                />
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="newPasswordConfirm">
                  Conferma Nuova Password
                </label>

                <Field
                  className="form-control"
                  name="newPasswordConfirm"
                  type="password"
                />

                <ErrorMessage
                  name="newPasswordConfirm"
                  render={(msg) => <span className="text-danger">{msg}</span>}
                />
              </div>

              <GradientButton
                className="btn-block btn btn-primary btn-lg"
                type="submit"
                loading={resetLoading}
              >
                Reimposta Password
              </GradientButton>
            </Form>
          </Formik>
        )
      )}
    </>
  );
};

export default ResetPassword;
