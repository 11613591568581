import React, { useContext, useState } from "react";
import { FetchContext } from "../../context/FetchContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import InputNote from "./InputNote";
import _ from "lodash";

const AddNote = ({ task, onAdd }) => {
  const fetchContext = useContext(FetchContext);

  const [isToggled, setIsToggled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAddNote = async (values) => {
    const postNote = {
      message: values.noteText,
      des_tipologia: values.noteCategory,
      des_cliente: values.des_cliente ? task.des_cliente : "",
      cod_ordine: values.cod_ordine ? task.cod_ordine : "",
      cod_parte: values.cod_parte ? task.cod_parte : "",
      cod_risorsa: values.cod_risorsa ? task.cod_risorsa : "",
      cod_campana: values.cod_campana ? task.cod_campana : "",
      cod_fustella: values.cod_fustella ? task.cod_fustella : "",
      cod_tassello: values.cod_tassello ? task.cod_tassello : "",
      cod_inserto: values.cod_inserto ? task.cod_inserto : "",
    };
    try {
      await fetchContext.authAxios
        .post("/api/v1/notes", postNote)
        .then((response) => {
          const note = response.data;
          if (_.isFunction(onAdd)) {
            onAdd(note);
          }
        });
    } catch (err) {
      // TODO: deal with errors!
      console.log(err);
    }
  };

  return (
    <div>
      {!isToggled && (
        <button
          className={`btn btn-primary btn-block btn-sm`}
          onClick={() => setIsToggled(!isToggled)}
        >
          <FontAwesomeIcon icon={faPlus} />
        </button>
      )}
      {isToggled && (
        <InputNote
          handleSubmit={(values) => {
            handleAddNote(values);
            setIsToggled(false);
          }}
          handleCancel={() => setIsToggled(false)}
          orderId={task.id}
        />
      )}
    </div>
  );
};

export default AddNote;
