import tasks from "./TasksMock";

let n = 5;
let orderIds = Array.apply(null, Array(n)).map(() => []);

for (let i = 0; i < 30; i++) {
  orderIds[i % n].push(`${Math.floor(Math.random() * tasks.length)}`);
}

let tools = [];
orderIds.forEach((elem, i) => {
  tools.push({
    id: `${i}`,
    orderIds: [...new Set(elem)],
    name: `Tool ${i}`,
  });
});

export default tools;
