export const own_note = ({ permissions, userId, note }) => {
  return (
    userId == note.id_user &&
    permissions.static.includes(`note_${note.des_tipologia}:edit`)
  );
};

export default {
  own_note,
};
