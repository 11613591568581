import tasks from "./TasksMock";

let notes = [];
let count = 0;

// categories
const categories = [
  "tracciabilità",
  "produzione",
  "manutenzione",
  "tecnica",
  "commerciale",
];

tasks.forEach((task) => {
  for (let i = 1; i < 4; i++) {
    notes.push({
      id: `${count}`,
      authorId: `${i}`,
      author: `Author ${i}`,
      text: `Nota ${i} per task ${task.id}`,
      taskId: task.id,
      category: categories[Math.floor(Math.random() * categories.length)],
      timestamp: new Date(),
    });
    count++;
  }
});

notes.sort((a, b) => b.id - a.id);

export default notes;
