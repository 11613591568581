import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { Container, Jumbotron, Tab, Tabs, Button } from "react-bootstrap";
import Alerts from "../components/DataEntry/Alerts";
import Ordini from "../components/DataEntry/Ordini";
import ListaMacchine from "../components/DataEntry/ListaMacchine";
import ArticoliApprovati from "../components/DataEntry/ArticoliApprovati";
import FermiProgrammati from "../components/DataEntry/FermiProgrammati";
import Clienti from "../components/DataEntry/Clienti";
import OrdiniSimulati from "../components/DataEntry/OrdiniSimulati";
import Parametri from "../components/DataEntry/Parametri";
import Produzioni from "../components/DataEntry/Produzioni";
import ArticoloPoseRisorse from "../components/DataEntry/SceltaRisorse";
import AltoVendenti from "../components/DataEntry/AltoVendenti";
import { useHistory } from "react-router-dom";
import { FetchContext } from "../context/FetchContext";
import Can from "../components/common/Can";

const endpoint_new_run = `/api/v1/run`;

const DataEntry = (props) => {
  const authContext = useContext(AuthContext);
  const [key, setKey] = useState("alerts");
  const fetchContext = useContext(FetchContext);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleRun = () => {
    const { id_utente } = authContext.getId();
    setLoading(true);

    fetchContext.authAxios
      .post(`${endpoint_new_run}`, {
        id_utente,
      })
      .then((response) => {
        setLoading(false);
        history.push("/run_list");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <Container fluid className={"mt-4"}>
      <Jumbotron className="py-3">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h1 style={{ display: "inline-block" }}>Inserimento Dati</h1>

          <div style={{ display: "inline-block", alignContent: "right" }}>
            <Can
              perform={"run:create"}
              yes={() => (
                <>
                  <h3>
                    {authContext.isAuthenticated() ? (
                      <Button onClick={handleRun} disabled={loading}>
                        Lancia Nuova Pianificazione
                      </Button>
                    ) : (
                      "false"
                    )}
                  </h3>
                </>
              )}
            />
          </div>
        </div>
      </Jumbotron>
      <Tabs
        defaultActiveKey="alerts"
        activeKey={key}
        id="uncontrolled-tab-example"
        variant="pills"
        onSelect={(key) => setKey(key)}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <Tab eventKey="alerts" title="Avvisi">
          <Alerts setKey={setKey} />
        </Tab>
        <Tab eventKey="articoli_approvati" title="Articoli Approvati">
          <ArticoliApprovati />
        </Tab>
        <Tab eventKey="ordini" title="Ordini">
          <Ordini />
        </Tab>
        <Tab eventKey="ordini_simulati" title="Ordini Simulati">
          <OrdiniSimulati />
        </Tab>
        <Tab eventKey="produzioni" title="Produzioni">
          <Produzioni />
        </Tab>
        <Tab eventKey="scelta_risorse" title="Scelta Risorse">
          <ArticoloPoseRisorse />
        </Tab>
        <Tab eventKey="manutenzioni" title="Fermi Programmati">
          <FermiProgrammati />
        </Tab>
        <Tab eventKey="clienti" title="Clienti">
          <Clienti />
        </Tab>
        <Tab eventKey="lista_macchine" title="Lista Macchine">
          <Can perform={"lista_macchine:visit"} yes={() => <ListaMacchine />} />
        </Tab>
        <Tab eventKey="parametri" title="Parametri">
          <Parametri />
        </Tab>
        <Tab eventKey="alto_vendenti" title="Altovendenti">
          <AltoVendenti />
        </Tab>
      </Tabs>
      <br></br>
    </Container>
  );
};

export default DataEntry;
