import React, { useState, useEffect, useContext } from "react";
import { FetchContext } from "../../context/FetchContext";
import { Card, CardContent, CardHeader, Container } from "@material-ui/core";

const Alerts = (props) => {
  const fetchContext = useContext(FetchContext);
  const [alertClienti, setAlertClienti] = useState();
  const [alertArticoliApprovati, setAlertArticoliApprovati] = useState();
  const [alertArticoliNonApprovati, setAlertArticoliNonApprovati] = useState();
  const [alertOrdini, setAlertOrdini] = useState();
  const [alertProduzioni, setAlertProduzioni] = useState();
  const [alertSceltaRisorse, setAlertSceltaRisorse] = useState();
  const [ultimoAggiornamento, setUltimoAggiornamento] = useState("");

  useEffect(() => {
    fetchAlert(fetchContext.authAxios, "lkp", "clienti", (number) =>
      setAlertClienti(number)
    );
    fetchAlert(fetchContext.authAxios, "lkp", "articoli_approvati", (number) =>
      setAlertArticoliApprovati(number)
    );
    fetchAlert(fetchContext.authAxios, "lkp", "ordini", (number) =>
      setAlertOrdini(number)
    );
    fetchAlert(fetchContext.authAxios, "lkp", "produzioni", (number) =>
      setAlertProduzioni(number)
    );

    fetchContext.authAxios
      .get(`/api/v1/articoli_non_approvati`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setAlertArticoliNonApprovati(data.occurrencies);
      })
      .catch((error) => {
        console.log(error);
      });

    fetchContext.authAxios
      .get(`/api/v1/ultimo_aggiornamento`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setUltimoAggiornamento(data.datetime);
      })
      .catch((error) => {
        console.log(error);
      });

    fetchAlert(
      fetchContext.authAxios,
      "lkp",
      "articolo_pose_risorse",
      (number) => setAlertSceltaRisorse(number)
    );
  }, []);

  return (
    <Container>
      <Card>
        <CardHeader title="Compilazioni Mancanti" />
        <CardContent>
          <p>
            Clienti a cui dare ancora priorità:{" "}
            <button
              className="btn btn-link btn-sm"
              onClick={() =>
                props.setKey ? props.setKey("clienti") : console.log("No prop")
              }
            >
              {alertClienti}
            </button>
          </p>
          <p>
            Articoli per i quali bisogna ancora definire lo stato di
            approvazione:{" "}
            <button
              className="btn btn-link btn-sm"
              onClick={() =>
                props.setKey
                  ? props.setKey("articoli_approvati")
                  : console.log("No prop")
              }
            >
              {alertArticoliApprovati}
            </button>
          </p>
          <p>
            Articoli con stato di approvazione: "Non ancora approvato":{" "}
            <button
              className="btn btn-link btn-sm"
              onClick={() =>
                props.setKey
                  ? props.setKey("articoli_approvati")
                  : console.log("No prop")
              }
            >
              {alertArticoliNonApprovati}
            </button>
          </p>
          <p>
            Ordini per i quali sono richieste informazioni ulteriori:{" "}
            <button
              className="btn btn-link btn-sm"
              onClick={() =>
                props.setKey ? props.setKey("ordini") : console.log("No prop")
              }
            >
              {alertOrdini}
            </button>
          </p>
          <p>
            Coppie (articolo, pose) per le quali mancano informazioni:{" "}
            <button
              className="btn btn-link btn-sm"
              onClick={() =>
                props.setKey
                  ? props.setKey("produzioni")
                  : console.log("No prop")
              }
            >
              {alertProduzioni}
            </button>
          </p>
          <p>
            Coppie (articolo, pose) per le quali non sono ancora state
            revisionate le risorse:{" "}
            <button
              className="btn btn-link btn-sm"
              onClick={() =>
                props.setKey
                  ? props.setKey("scelta_risorse")
                  : console.log("No prop")
              }
            >
              {alertSceltaRisorse}
            </button>
          </p>
          <p>Ultimo aggiornamento dei dati: {ultimoAggiornamento}</p>
        </CardContent>
      </Card>
    </Container>
  );
};

const fetchAlert = (
  axiosObj,
  schema,
  table,
  onResponse,
  onError = (error) => console.log(error)
) => {
  axiosObj
    .get(`/api/v1/touch?schema=${schema}&table=${table}`)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      onResponse(data.occurrencies);
    })
    .catch((error) => {
      onError(error);
    });
};

export default Alerts;
