import React, { useContext, useEffect, useState } from "react";
import { FetchContext } from "../../context/FetchContext";
import AuthorizedTable from "../common/AuthorizedTable";
import _ from "lodash";
import { MTableCell, MTableEditField } from "material-table";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import { isInteger, isNotEmpty } from "./utils";

const resource = "ordini";
const title = "Ordini";
const endpoint = `/api/v1/ordini`;

const Ordini = () => {
  const fetchContext = useContext(FetchContext);
  const [occurrencies, setOccurrencies] = useState("..");
  const [isLoading, setIsLoading] = useState(true);
  const [columns] = useState([
    {
      title: "Codice Ordine",
      field: "cod_ordine",
      type: "string",
      align: "center",
      editable: "never",
    },
    {
      title: "Codice Articolo",
      field: "cod_parte",
      type: "string",
      align: "center",
      editable: "never",
    },
    {
      title: "Numero Pose Previste",
      field: "val_pose_previsto",
      type: "numeric",
      align: "center",
      editable: "never",
    },
    {
      title: "Battute Ora previste",
      field: "val_battute_ora_previste",
      type: "numeric",
      align: "center",
      editable: "never",
    },
    {
      title: "Cliente",
      field: "des_cliente",
      type: "string",
      align: "center",
      editable: "never",
    },
    {
      title: "MTEC",
      field: "des_mtec",
      type: "string",
      align: "center",
      editable: "never",
    },
    {
      title: "Data Inserimento Ordine",
      field: "dat_inserimento",
      type: "date",
      align: "center",
      editable: "never",
    },
    {
      title: "Codice Materia Prima",
      field: "cod_materia_prima",
      type: "string",
      align: "center",
      editable: "never",
    },
    {
      title: "Materia Prima Necessaria",
      field: "qta_materia_prima_necessaria",
      type: "numeric",
      align: "center",
      validate: ({ qta_materia_prima_necessaria }) =>
        isNotEmpty(qta_materia_prima_necessaria),
    },
    {
      title: "Data Arrivo Materia Prima",
      field: "dat_arrivo_materia_prima",
      type: "date",
      align: "center",
    },
    {
      title: "Risorsa Assegnata",
      field: "cod_risorsa",
      type: "string",
      align: "center",
      editable: "never",
    },
    {
      title: "Pezzi Ordinati",
      field: "val_pezzi_ordinati",
      type: "numeric",
      align: "center",
      validate: ({ val_pezzi_ordinati }) => isInteger(val_pezzi_ordinati),
    },
    {
      title: "Pezzi Buoni Prodotti",
      field: "val_pezzi_buoni",
      type: "numeric",
      align: "center",
      editable: "never",
    },
    {
      title: "Terzista",
      field: "flg_terzista",
      lookup: { true: "Si", false: "No" },
      align: "center",
      editComponent: (props) => editBooleanSelect(props),
      validate: ({ flg_terzista }) => isNotEmpty(flg_terzista),
    },
    {
      title: "Stato",
      field: "flg_attivo",
      lookup: { true: "Attivo", false: "Non Attivo" },
      align: "center",
      editComponent: (props) => editBooleanSelect(props),
      validate: ({ flg_attivo }) => isNotEmpty(flg_attivo),
    },
    {
      title: "Data Consegna",
      field: "dat_consegna",
      type: "date",
      align: "center",
      validate: ({ dat_consegna }) => isNotEmpty(dat_consegna),
    },
    {
      title: "Tassatività Ordine",
      field: "flg_forzatura",
      lookup: { true: "Si", false: "No" },
      align: "center",
      validate: ({ flg_forzatura }) => isNotEmpty(flg_forzatura),
    },
    {
      title: "Acconto",
      field: "flg_acconto",
      lookup: { true: "Si", false: "No" },
      align: "center",
      editComponent: (props) => editBooleanSelect(props),
      validate: ({ flg_acconto }) => isNotEmpty(flg_acconto),
    },
    {
      title: "Quantità Prima Tranche",
      field: "val_qnt_prima_tranche",
      type: "numeric",
      align: "center",
      editComponent: editAccontiCell,
      validate: ({ val_qnt_prima_tranche, flg_acconto }) => {
        if (!flg_acconto) {
          return true;
        }
        return isInteger(val_qnt_prima_tranche);
      },
    },
    {
      title: "Data scadenza Prima Tranche",
      field: "dat_dl_prima_tranche",
      type: "date",
      align: "center",
      editComponent: editAccontiCell,
      validate: ({ dat_dl_prima_tranche, flg_acconto }) => {
        if (!flg_acconto) {
          return true;
        }
        return dat_dl_prima_tranche == ""
          ? { isValid: false, helperText: "Campo Obbligatorio" }
          : true;
      },
    },
    {
      title: "Quantità Seconda Tranche",
      field: "val_qnt_seconda_tranche",
      type: "numeric",
      align: "center",
      editComponent: editAccontiCell,
      validate: ({ val_qnt_seconda_tranche, flg_acconto }) => {
        if (!flg_acconto) {
          return true;
        }
        return isInteger(val_qnt_seconda_tranche);
      },
    },
    {
      title: "Data scadenza Seconda Tranche",
      field: "dat_dl_seconda_tranche",
      type: "date",
      align: "center",
      editComponent: editAccontiCell,
      validate: ({ dat_dl_seconda_tranche, flg_acconto }) => {
        if (!flg_acconto) {
          return true;
        }
        return dat_dl_seconda_tranche == ""
          ? { isValid: false, helperText: "Campo Obbligatorio" }
          : true;
      },
    },
    {
      title: "Compilato",
      field: "flg_touch",
      type: "boolean",
      align: "center",
      defaultSort: "asc",
      editable: "never",
    },
  ]);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchContext.authAxios
      .get(`${endpoint}`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        console.log(data)
        setData(data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    fetchContext.authAxios
      .get(`/api/v1/touch?schema=lkp&table=${resource}`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setOccurrencies(data.occurrencies);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <AuthorizedTable
        resource={resource}
        title={`${title} (da compilare ${occurrencies})`}
        columns={columns}
        data={data}
        isLoading={isLoading}
        editable={{
          onRowUpdate: (newData, rest) => {
            return new Promise((resolve, reject) => {
              // let isDataOk = true;
              // Object.keys(newData).forEach((key) => {
              //   if (newData[key] === null) isDataOk = false;
              // });
              // console.log("isDataOk", isDataOk);
              const { tableData, ...oldData } = rest;
              if (_.isEqual(newData, oldData)) {
                resolve();
              } else {
                fetchContext.authAxios
                  .post(`${endpoint}`, {
                    ...newData,
                  })
                  .then((response) => {
                    if (response.data) {
                      return response.data;
                    }
                  })
                  .then((row) => {
                    const dataUpdate = _.cloneDeep(data);
                    dataUpdate[tableData.id] = row;
                    setData([...dataUpdate]);
                    resolve();
                  })
                  .catch((error) => {
                    console.log(error);
                    reject();
                  });
              }
            });
          },
        }}
      />
    </>
  );
};

const editAccontiCell = (props) => {
  const { flg_acconto } = props.rowData;
  return !flg_acconto ? <div /> : <MTableEditField {...props} />;
};

const editBooleanSelect = (props) => {
  return (
    <FormControl error={Boolean(props.error)}>
      <Select
        labelId="multiple-resource-label"
        id="boolean-resource"
        value={props.value}
        onChange={(event) => {
          return props.onChange(event.target.value == "true");
        }}
        input={<Input id="select-boolean" />}
        renderValue={(selected) => (
          <div>{props.columnDef.lookup[selected]}</div>
        )}
      >
        {Object.keys(props.columnDef.lookup).map((key) => (
          <MenuItem key={key} value={key}>
            {props.columnDef.lookup[key]}
          </MenuItem>
        ))}
      </Select>
      {Boolean(props.helperText) && (
        <FormHelperText>{props.helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default Ordini;
