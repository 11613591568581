import React, { forwardRef, useImperativeHandle, useState, useRef, useLayoutEffect } from "react";
import { Form } from "react-bootstrap";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import _ from "lodash";

am4core.useTheme(am4themes_animated);

const RISORSA_ORDER = [
  "03",
  "04",
  "05",
  "06",
  "08",
  "011",
  "07",
  "010",
  "09",
  "012",
  "015",
  "014",
  "013",
  "terzista",
];
const FIELD_KEYS = {
  RISORSA: "cod_risorsa",
  ARTICOLO: "cod_parte",
  CLIENTE: "des_cliente",
  ORDINE: "cod_ordine",
};

const Gantt = forwardRef(({ tasks, onTaskSelect }, ref) => {
  const [filter, setFilter] = useState("");

  const chartRef = useRef(null);
  let colorSet = new am4core.ColorSet();

  const filterChangeHandler = (event) => {
    const filter = event.target.value;

    setFilter(filter);
  };

  useImperativeHandle(ref, () => ({
    setDateRange([startDate, endDate]) {
      if (!startDate || !endDate) {
        return;
      }
      chartRef.current.xAxes.getIndex(0).zoomToDates(
        startDate.toDate(), endDate.toDate()
      );
    }
  }));

  useLayoutEffect(() => {
    const chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    chart.paddingRight = 30;
    chart.dateFormatter.inputDateFormat = "yyyy-MM-dd HH:mm:ss";

    colorSet.saturation = 0.4;

    const filteredTasks = tasks
      .filter((t) =>
        _.values(t).some((v) =>
          String(v).toUpperCase().includes(filter.toUpperCase())
        )
      )
      .sort(
        (a, b) =>
          RISORSA_ORDER.indexOf(a[FIELD_KEYS.RISORSA]) -
          RISORSA_ORDER.indexOf(b[FIELD_KEYS.RISORSA])
      );

    chart.data = filteredTasks;

    const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = FIELD_KEYS.RISORSA;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.inversed = true;

    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 100;
    dateAxis.baseInterval = { count: 15, timeUnit: "minute" };
    dateAxis.renderer.tooltipLocation = 0;

    const series1 = chart.series.push(new am4charts.ColumnSeries());
    series1.columns.template.height = am4core.percent(70);
    series1.columns.template.tooltipHTML = `
      <div class="container">
        <div class="row row-cols-2">
          <div class="col">
            <strong>Articolo: </strong>{${FIELD_KEYS.ARTICOLO}}
          </div>
          <div class="col">
            <strong>Cliente: </strong>{${FIELD_KEYS.CLIENTE}}
          </div>
          <div class="col">
            <strong>Stampo: </strong>{${FIELD_KEYS.RISORSA}}
          </div>
          <div class="col">
            <strong>Ordine: </strong>{${FIELD_KEYS.ORDINE}}
          </div>

        </div>
        <hr />
      </div>`;

    series1.dataFields.openDateX = "start_time";
    series1.dataFields.dateX = "end_time";
    series1.dataFields.categoryY = FIELD_KEYS.RISORSA;
    series1.columns.template.propertyFields.fill = "color"; // get color of column from data
    series1.columns.template.propertyFields.stroke = "border_color"; //get border color from data
    series1.columns.template.strokeWidth = 0;
    series1.columns.template.propertyFields.strokeWidth = "stroke_width"; //get border size from data
    series1.columns.template.strokeOpacity = 1;
    series1.columns.template.togglable = true;
    series1.columns.template.strokeDasharray = "0.0";

    //Write inside task/ordine
    const label = series1.columns.template.createChild(am4core.Label);
    label.html = `<div class='text-left'>{${FIELD_KEYS.CLIENTE}}</div>`;
    label.valign = "middle";
    label.strokeWidth = 0;
    label.align = "left";
    label.truncate = true;
    label.propertyFields.fill = "text_color"; // get color of text from data

    //Set Active status when click on Order And Growing Border Size and set Border Color
    const tActiveState = series1.columns.template.states.create("active");
    tActiveState.properties.strokeDasharray = "3,3";
    tActiveState.properties.strokeWidth = 4;
    tActiveState.properties.stroke = am4core.color("#000");

    series1.columns.template.events.on("hit", (event) => {
      //Deactivate status to Order that was Active
      series1.columns.each((column) => {
        if (column !== event.target) {
          column.setState("default");
          column.isActive = false;
        }
      });

      if (_.isFunction(onTaskSelect)) {
        const task = event.target._dataItem._dataContext;
        onTaskSelect(task);
      }
    });

    chart.scrollbarX = new am4core.Scrollbar();

    chartRef.current = chart;

    return () => {
      chart.dispose();
    };
  }, [tasks, filter]);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <h5>Gantt</h5>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Filtra..."
              onChange={filterChangeHandler}
            />
          </Form.Group>
          <div id="chartdiv" style={{ width: "100%", height: "700px" }}></div>
        </div>
      </div>
    </>
  );
});

export default Gantt;
