import React, { useContext, useEffect, useRef, useState } from "react";
import Gantt from "./Gantt";
import TaskDetail from "./TaskDetail";
import { FetchContext } from "../context/FetchContext";

import moment from "moment";
import "moment/locale/it";

import MomentAdapter from "@material-ui/lab/AdapterMoment";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import MobileDateRangePicker from "@material-ui/lab/MobileDateRangePicker";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Chip from "@material-ui/core/Chip";

import { Form } from "react-bootstrap";

import _ from "lodash";
import Kpi from "./Kpi";

const BOOLEAN_FILTERS = [
  {
    field: "flg_serbatoio",
    label: "Serbatoi",
  },
  {
    field: "flg_articolo_nuovo",
    label: "Articoli nuovi",
  },
  {
    field: "flg_ordine_acconto",
    label: "Acconti",
  },
  {
    field: "flg_articolo_approvato",
    label: "Articoli approvati",
  },
  {
    field: "flg_articolo_approvato_false",
    label: "Articoli non approvati / in fase di approvazione",
  },
  {
    field: "flg_ordine_nuovo",
    label: "Ordini nuovi",
  },
  {
    field: "flg_ordine_ritardo",
    label: "Ordini in ritardo",
  },
  {
    field: "flg_ordine_simulato",
    label: "Impegni / Ordini simulati",
  },
  {
    field: "flg_ordini_modificati_lkp",
    label: "Ordini modificati",
  },
  {
    field: "has_todo_notes",
    label: "Manutenzioni da fare"
  }
];

const KPI = [
  {
    field: "kpi_otif",
    label: "otif",
  },
  {
    field: "kpi_saturazione",
    label: "saturazione",
  },
];

const GanttContainer = (props) => {
  const { runId } = props;
  const ganttRef = useRef(null);
  const fetchContext = useContext(FetchContext);

  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [loading, setLoading] = useState(true);

  const [dateRange, setDateRange] = React.useState([null, null]);
  let _tempDateRange = [null, null];

  const [selectedResources, setSelectedResources] = useState([]);
  const [booleanFilters, setBooleanFilters] = useState(
    BOOLEAN_FILTERS.reduce((obj, f) => {
      obj[f.field] = false;
      return obj;
    }, {})
  );

  const fetchTasks = async () => {
    if (!runId) {
      return;
    }

    setLoading(true);

    try {
      const response = await fetchContext.authAxios.get(
        `/api/v1/solutions/${runId}`
      );

      const { data } = response;
      if (data) {
        setTasks(data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const filterTasks = () => {
    const filteredTasks = tasks
      .filter((t) => {
        if (_.isEmpty(selectedResources)) {
          return true;
        }

        return selectedResources.includes(t.cod_risorsa);
      })
      .filter((t) =>
        _.entries(booleanFilters)
          .filter(([field, value]) => value)
          .every(([field, value]) => {
            if (field === "flg_articolo_approvato_false") {
              return t["flg_articolo_approvato"] !== value;
            }
            return t[field] === value;
          })
      );

    setFilteredTasks(filteredTasks);
  };

  const taskSelectHandler = (task) => {
    setSelectedTask(task);
  };

  const selectedResourcesHandler = (selected) => {
    setSelectedResources(selected);
  };

  const booleanFilterHandler = (field) => () => {
    setBooleanFilters({
      ...booleanFilters,
      [field]: !booleanFilters[field],
    });
  };

  useEffect(() => {
    fetchTasks();
  }, [runId]);
  useEffect(filterTasks, [tasks, selectedResources, booleanFilters]);

  const _renderLoading = () => {
    return <h5>Loading...</h5>;
  };

  if (loading) {
    return _renderLoading();
  }

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col">
              <LocalizationProvider dateAdapter={MomentAdapter} locale="it">
                <MobileDateRangePicker
                  startText="Data Inizio"
                  endText="Data Fine"
                  value={dateRange}
                  onChange={(newValue) => {
                    _tempDateRange = newValue;
                  }}
                  onClose={() => {
                    setDateRange(_tempDateRange);
                    ganttRef.current.setDateRange(_tempDateRange);
                  }}
                  renderInput={(startProps, endProps) => (
                    <React.Fragment>
                      <TextField {...startProps} variant="standard" />
                      <Box sx={{ mx: 2 }}> a </Box>
                      <TextField {...endProps} variant="standard" />
                    </React.Fragment>
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="col">
              <FormControl style={{ width: "150px" }}>
                <InputLabel>Risorse</InputLabel>
                <Select
                  labelId="multiple-resource-label"
                  id="multiple-resource"
                  multiple
                  value={selectedResources}
                  onChange={(event) =>
                    selectedResourcesHandler(event.target.value)
                  }
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <div>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </div>
                  )}
                >
                  {_.uniq(tasks.map((t) => t.cod_risorsa)).map((risorsa) => (
                    <MenuItem key={risorsa} value={risorsa}>
                      {risorsa}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col">
              <div className="row">
                {BOOLEAN_FILTERS.map((f) => (
                  <Form.Check
                    inline
                    label={f.label}
                    type="checkbox"
                    id={f.field}
                    key={f.field}
                    name={f.field}
                    checked={booleanFilters[f.field]}
                    onChange={booleanFilterHandler(f.field)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="row">
            {KPI.map(({ field, label }) => (
              <Kpi
                key={label}
                name={label}
                value={tasks[0] ? tasks[0][field] : 0}
              />
            ))}
          </div>
        </div>
      </div>
      <Gantt ref={ganttRef} tasks={filteredTasks} onTaskSelect={taskSelectHandler} />
      {selectedTask && <TaskDetail task={selectedTask} />}
    </>
  );
};

export default GanttContainer;
