import moment from "moment";

let ordini = [];

const randomDate = () => {
  return moment(
    new Date(+new Date() - Math.floor(Math.random() * 10000000000))
  ).format("MM/DD/YYYY");
};

const randomCode = (length) => {
  var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split(
    ""
  );
  var b = [];
  for (var i = 0; i < length; i++) {
    var j = (Math.random() * (a.length - 1)).toFixed(0);
    b[i] = a[j];
  }
  return b.join("");
};

const randomNumber = (maximum) => {
  return Math.floor(Math.random() * maximum) + 1;
};

const randomBoolean = () => {
  if (!Math.round(Math.random()) == true) return "Si";
  else return "No";
};

for (let i = 0; i < 20; i++) {
  ordini.push({
    "Codice Ordine": randomCode(6),
    "Codice Cliente": randomCode(8),
    "Codice Articolo": randomCode(10),
    "Numero Pose Previsto": randomNumber(2),
    "Flag Acconto": randomBoolean(),
    "Flag Terzista": randomBoolean(),
    "Flag Attivo": randomBoolean(),
    "Numero Pezzi Ordinati": randomNumber(100),
    "Data Consegna": randomDate(),
    "Quantità Materia Prima Necessaria": randomNumber(50),
    "Data Arrivo Materia Prima": randomDate(),
  });
}

export default ordini;
