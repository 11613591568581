import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import { Form } from "react-bootstrap";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import _ from "lodash";

am4core.useTheme(am4themes_animated);

const KPI_NAMES = {
  otif: "Ordini in Orario",
  oee: "OEE",
  rendimento: "Rendimento",
  saturazione: "Saturazione",
  qualita: "Qualità",
};

const Kpi = ({ name, value }) => {
  const chartRef = useRef(null);
  const chartId = `${name}_chart`;

  useLayoutEffect(() => {
    const _value = Math.round((value + Number.EPSILON) * 10) / 10;

    const chart = am4core.create(chartId, am4charts.GaugeChart);
    chart.innerRadius = am4core.percent(82);

    /**
     * Normal axis
     */

    const axis = chart.xAxes.push(new am4charts.ValueAxis());
    axis.min = 0;
    axis.max = 100;
    axis.strictMinMax = true;
    axis.renderer.radius = am4core.percent(80);
    axis.renderer.inside = true;
    axis.renderer.line.strokeOpacity = 1;
    axis.renderer.ticks.template.disabled = false;
    axis.renderer.ticks.template.strokeOpacity = 1;
    axis.renderer.ticks.template.length = 5;
    axis.renderer.grid.template.disabled = true;
    axis.renderer.labels.template.radius = 23;
    axis.renderer.labels.template.fontSize = am4core.percent(60);
    axis.renderer.labels.template.adapter.add("text", (text) => {
      return text;
    });

    /**
     * Axis for ranges
     */

    const colorSet = new am4core.ColorSet();

    const axis2 = chart.xAxes.push(new am4charts.ValueAxis());
    axis2.min = 0;
    axis2.max = 100;
    axis2.strictMinMax = true;
    axis2.renderer.labels.template.disabled = true;
    axis2.renderer.ticks.template.disabled = true;
    axis2.renderer.grid.template.disabled = true;

    const range0 = axis2.axisRanges.create();
    range0.value = 0;
    range0.endValue = _value;
    range0.axisFill.fillOpacity = 1;
    range0.axisFill.fill = "#E84B5E";

    const range1 = axis2.axisRanges.create();
    range1.value = _value;
    range1.endValue = 100;
    range1.axisFill.fillOpacity = "1";
    range1.axisFill.fill = "#F9E7BF";

    /**
     * Label
     */

    const label = chart.radarContainer.createChild(am4core.Label);
    label.isMeasured = false;
    label.x = am4core.percent(50);
    label.y = am4core.percent(100);
    label.fontSize = am4core.percent(100);
    label.horizontalCenter = "middle";
    label.verticalCenter = "bottom";
    label.text = `${_value}%`;

    /**
     * Hand
     */

    const hand = chart.hands.push(new am4charts.ClockHand());
    hand.axis = axis2;
    hand.innerRadius = am4core.percent(35);
    hand.startWidth = 3;
    hand.pin.disabled = true;
    hand.fill = "#00475C";
    hand.stroke = "#00475C";
    hand.value = _value;

    chartRef.current = chart;

    return () => {
      chart.dispose();
    };
  }, [value]);

  return (
    <div className="col" style={{ textAlign: "center" }}>
      <h5>{KPI_NAMES[name] || name}</h5>
      <div id={chartId}></div>
    </div>
  );
};

export default Kpi;
