import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisV,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import CircleButton from "./../common/CircleButton";

const EditNoteToggle = ({
  handleEdit,
  handleDelete,
  handleToggle,
  isToggled,
}) => {
  // const [isToggled, setIsToggled] = useState(false);

  return (
    <div className="dropdown no-arrow dropleft">
      <button
        className="btn"
        onClick={(event) => {
          handleToggle(event);
        }}
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </button>
      {isToggled && (
        <div
          className="d-flex flex-row my-1 mr-2"
          style={{ position: "absolute", top: 0, right: "100%", left: "auto" }}
        >
          <div className="mx-1">
            <CircleButton
              className="btn-sm btn-success"
              onClick={(event) => {
                handleEdit(event);
                // setIsToggled(!isToggled);
              }}
              icon={faEdit}
            />
          </div>
          <div className="mx-1">
            <CircleButton
              className="btn-sm btn-danger"
              onClick={(event) => {
                handleDelete(event);
                // setIsToggled(!isToggled);
              }}
              icon={faTrash}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EditNoteToggle;
