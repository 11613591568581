import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { FetchContext } from "../../context/FetchContext";

import InputNote from "./InputNote";
import EditNoteToggle from "./EditNoteToggle";
import Can from "../common/Can";
import _ from "lodash";
import categories from "../../mock/CategoriesMock";

const Note = ({ note, task, onDelete, onEdit }) => {
  const authContext = useContext(AuthContext);
  const fetchContext = useContext(FetchContext);

  const { id_user, username, message, created_time, tipologia } = note;

  const [isEditing, setIsEditing] = useState(false);
  const [isEditToggled, setIsEditToggled] = useState(false);

  useEffect(() => {
    setIsEditing(false);
    setIsEditToggled(false);
  }, [note]);

  const handleEdit = async (values) => {
    console.log("handleEdit note", values);
    const notaEdit = {
      ...note,
      des_message: values.noteText,
      des_tipologia: values.noteCategory,
      des_cliente: values.des_cliente ? task.des_cliente : "",
      cod_ordine: values.cod_ordine ? task.cod_ordine : "",
      cod_parte: values.cod_parte ? task.cod_parte : "",
      cod_risorsa: values.cod_risorsa ? task.cod_risorsa : "",
      cod_campana: values.cod_campana ? task.cod_campana : "",
      cod_fustella: values.cod_fustella ? task.cod_fustella : "",
      cod_tassello: values.cod_tassello ? task.cod_tassello : "",
      cod_inserto: values.cod_inserto ? task.cod_inserto_1 : "",
    };

    try {
      await fetchContext.authAxios
        .put("/api/v1/notes", notaEdit)
        .then((response) => {
          if (_.isFunction(onEdit)) {
            onEdit(notaEdit);
          }
        });
    } catch (err) {
      // TODO: deal with errors!
      console.log(err);
    }
  };

  const handleDelete = async () => {
    try {
      const { id } = note;
      await fetchContext.authAxios.delete(`/api/v1/notes?id=${id}`);

      if (_.isFunction(onDelete)) {
        onDelete(note);
      }
    } catch (err) {
      // TODO: deal with errors!
      console.log(err);
    }
  };

  const getLinkedAttributes = () => {
    const attributes = [];

    if (note.des_cliente) {
      attributes.push("Cliente");
    }
    if (note.cod_ordine) {
      attributes.push("Ordine");
    }
    if (note.cod_parte) {
      attributes.push("Articolo");
    }
    if (note.cod_risorsa) {
      attributes.push("Risorsa");
    }
    if (note.cod_campana) {
      attributes.push("Campana");
    }
    if (note.cod_fustella) {
      attributes.push("Fustella");
    }
    if (note.cod_tassello) {
      attributes.push("Tassello");
    }
    if (note.cod_inserto != "") {
      attributes.push("Inserto");
    }

    return attributes;
  };

  return (
    <div className="d-flex flex-row align-items-center justify-content-between px-0">
      <div>
        {!isEditing ? (
          <>
            <h5>{categories.find((c) => c.tag === tipologia).value}</h5>
            <p className="mb-1">{message}</p>
            <p className="mb-1">
              <small className="text-muted font-italic">
                {getLinkedAttributes().map((a, i) => (
                  <span className="badge badge-pill badge-success" key={i}>
                    {a}
                  </span>
                ))}
              </small>
            </p>
          </>
        ) : (
          <InputNote
            note={note}
            handleSubmit={(values) => {
              handleEdit(values).then(() => {
                setIsEditing(false);
              });
            }}
            handleCancel={() => {
              setIsEditing(false);
            }}
          />
        )}
        <p className="mb-0">
          <small className="text-muted font-italic">
            {[username, created_time].join(" • ")}
          </small>
        </p>
      </div>
      {!isEditing && (
        <Can
          perform="note:edit"
          data={{ userId: authContext.getId(), note }}
          yes={() => (
            <EditNoteToggle
              handleEdit={() => {
                setIsEditing(true);
                setIsEditToggled(false);
              }}
              handleDelete={() => {
                handleDelete();
              }}
              handleToggle={() => {
                setIsEditToggled(!isEditToggled);
              }}
              isToggled={isEditToggled}
            />
          )}
        />
      )}
    </div>
  );
};

export default Note;
