import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { FetchContext } from "../context/FetchContext";
import NoteList from "./Note/NoteList";
import AddNote from "./Note/AddNote";
import _ from "lodash";

const toQueryString = function (obj) {
  return _.map(obj, function (v, k) {
    return encodeURIComponent(k) + "=" + encodeURIComponent(v);
  }).join("&");
};

const TASK_FIELDS = [
  {
    label: "Produzione",
    fields: [
      {
        field: "cod_risorsa",
        label: "Risorsa",
      },
      {
        field: "des_cliente",
        label: "Cliente",
      },
      {
        field: "start_time",
        label: "Data Inizio Produzione",
      },
      {
        field: "end_time",
        label: "Data Fine Produzione",
      },
      {
        field: "val_battute_orarie",
        label: "B/H Stimate",
      },
    ],
  },
  {
    label: "Dettaglio Ordine",
    fields: [
      {
        field: "cod_ordine_1",
        label: "Codice Ordine",
      },
      {
        field: "cod_parte_1",
        label: "Codice Articolo",
      },
      {
        field: "dat_consegna_1",
        label: "Data Consegna Ordine",
      },
      {
        field: "val_pezzi_ordinati_1",
        label: "Pezzi Ordinati ODL Ordine",
      },
      {
        field: "val_pose_1",
        label: "Numero Pose Ordine",
      },
    ],
  },
  {
    label: "Attrezzatura",
    fields: [
      {
        field: "cod_materia_prima",
        label: "Codice Materia Prima Utilizzata",
      },
      {
        field: "cod_campana",
        label: "Codice Campana Utilizzata",
      },
      {
        field: "cod_fustella",
        label: "Codice Fustella Utilizzata",
      },
      {
        field: "cod_tassello",
        label: "Codice Tassello Utilizzato",
      },
      {
        field: "cod_inserto_1",
        label: "Codice Inserto 1 Utilizzato",
      },
      {
        field: "cod_inserto_2",
        label: "Codice Inserto 2 Utilizzato",
      },
    ],
  },
  {
    label: "Dettaglio Ordine 2 (se ordine misto)",
    fields: [
      {
        field: "cod_ordine_2",
        label: "Codice Ordine 2",
      },
      {
        field: "cod_parte_2",
        label: "Codice Articolo 2",
      },
      {
        field: "dat_consegna_2",
        label: "Data Consegna Ordine 2",
      },
      {
        field: "val_pezzi_ordinati_2",
        label: "Pezzi Ordinati ODL Ordine 2",
      },
      {
        field: "val_pose_2",
        label: "Numero Pose Ordine 2",
      },
    ],
  },
];

const TaskDetail = ({ task, disableNotes }) => {
  const fetchContext = useContext(FetchContext);

  const [notes, setNotes] = useState([]);

  const [isFetchingNotes, setIsFetchingNotes] = useState(true);

  const fetchNotes = async () => {
    setIsFetchingNotes(true);
    try {
      const { data } = await fetchContext.authAxios.get(
        `/api/v1/notes?${toQueryString(task)}`
      );
      setNotes(data);
      setIsFetchingNotes(false);
    } catch (err) {
      console.log(err);
      setIsFetchingNotes(false);
    }
  };

  useEffect(() => {
    if (!disableNotes) {
      fetchNotes();
    }
  }, [task, disableNotes]);

  const noteAddHandler = (note) => {
    setNotes([...notes, note]);
  };

  const noteDeleteHandler = (note) => {
    setNotes(notes.filter((n) => n.id !== note.id));
  };

  const noteEditHandler = (note) => {
    setNotes(notes.map((n) => (n.id === note.id ? note : n)));
  };

  return (
    <div className="card">
      <div className="card-body">
        <h4>
          <strong>Task</strong>
        </h4>
        <div className="container-fluid">
          <div className="row">
            <div className="col-6">
              <div className="row row-cols-1 row-cols-xl-2">
                {TASK_FIELDS.map((section, i) => {
                  if (
                    section.label === "Dettaglio Ordine 2" &&
                    !task[section.fields[0]]
                  ) {
                    return "";
                  }
                  return (
                    <div key={i}>
                      <h5>
                        <strong>{section.label}</strong>
                      </h5>
                      {section.fields.map((field, i) => (
                        <div key={i}>
                          <strong>{field.label}</strong>: {task[field.field]}
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>
            </div>
            {!disableNotes && (
              <div className="col-6">
                {!isFetchingNotes ? (
                  <>
                    <AddNote task={task} onAdd={noteAddHandler} />
                    <NoteList
                      notes={notes}
                      task={task}
                      onDelete={noteDeleteHandler}
                      onEdit={noteEditHandler}
                    />
                  </>
                ) : (
                  <h5>Loading..</h5>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskDetail;
