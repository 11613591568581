import React, { useContext, useEffect, useState } from "react";
import AuthorizedTable from "../common/AuthorizedTable";
import { FetchContext } from "../../context/FetchContext";
import _ from "lodash";
import { isNotEmpty } from "./utils";

const resource = "articoli_approvati";
const title = "Articoli Approvati";
const endpoint = `/api/v1/approved_articles`;

const ArticoliApprovati = () => {
  const fetchContext = useContext(FetchContext);
  const [occurrencies, setOccurrencies] = useState("..");
  const [isLoading, setIsLoading] = useState(true);
  const [columns] = useState([
    {
      title: "Codice Articolo",
      field: "cod_parte",
      type: "string",
      align: "center",
      editable: "never",
    },
    {
      title: "Stato",
      field: "flg_approvato",
      lookup: { true: "Approvato", false: "Da Approvare" },
      align: "center",
      validate: ({ flg_approvato }) =>
        flg_approvato === ""
          ? { isValid: false, helperText: "Campo Obbligatorio" }
          : true,
    },
    {
      title: "Compilato",
      field: "flg_touch",
      type: "boolean",
      align: "center",
      defaultSort: "asc",
      editable: "never",
    },
  ]);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchContext.authAxios
      .get(`${endpoint}`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    fetchContext.authAxios
      .get(`/api/v1/touch?schema=lkp&table=${resource}`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setOccurrencies(data.occurrencies);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <AuthorizedTable
      resource={resource}
      title={`${title} (da compilare ${occurrencies})`}
      columns={columns}
      data={data}
      isLoading={isLoading}
      editable={{
        onRowUpdate: (newData, rest) => {
          return new Promise((resolve, reject) => {
            const { tableData, ...oldData } = rest;
            if (_.isEqual(newData, oldData)) {
              resolve();
            } else {
              fetchContext.authAxios
                .put(`${endpoint}`, {
                  ...newData,
                })
                .then((response) => {
                  if (response.data) {
                    return response.data;
                  }
                })
                .then((row) => {
                  const dataUpdate = _.cloneDeep(data);
                  dataUpdate[tableData.id] = row;
                  setData([...dataUpdate]);
                  resolve();
                })
                .catch((error) => {
                  console.log(error);
                  reject();
                });
            }
          });
        },
      }}
    />
  );
};

export default ArticoliApprovati;
