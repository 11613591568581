import React, { useContext, useEffect, useState } from "react";
import { FetchContext } from "../../context/FetchContext";
import { AuthContext } from "../../context/AuthContext";
import AuthorizedTable from "../common/AuthorizedTable";
import _ from "lodash";
import { MTableEditField } from "material-table";
import { isInteger, isNotEmpty, hasNullValues } from "./utils";

const simulationPrefix = "SIM-";
const resource = "ordini_simulati";
const title = "Ordini Simulati";
const endpoint = `/api/v1/ordini_simulati`;

const OrdiniSimulati = () => {
  const fetchContext = useContext(FetchContext);
  const authContext = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState([
    {
      title: "Codice Ordine",
      field: "cod_ordine",
      type: "string",
      align: "center",
      editable: "onAdd",
      editComponent: (props) => (
        <div style={{ display: "flex" }}>
          <p style={{ margin: "4px" }}>{simulationPrefix}</p>
          <MTableEditField {...props} />
        </div>
      ),
      validate: ({ cod_ordine }) => isNotEmpty(cod_ordine),
    },
    {
      title: "Codice Articolo / MTEC",
      field: "cod_parte",
      type: "string",
      align: "center",
      validate: ({ cod_parte }) => isNotEmpty(cod_parte),
    },
    {
      title: "Pose Previste",
      field: "val_pose_previsto",
      type: "numeric",
      align: "center",
      editable: "onAdd",
      validate: ({ val_pose_previsto }) => isInteger(val_pose_previsto),
    },
    {
      title: "Pezzi Ordinati",
      field: "val_pezzi_ordinati",
      type: "numeric",
      align: "center",
      editable: "onAdd",
      validate: ({ val_pezzi_ordinati }) => isInteger(val_pezzi_ordinati),
    },
    {
      // Da non modificare, data di creazione della riga
      title: "Data Inserimento",
      field: "dat_inserimento",
      type: "date",
      align: "center",
      editable: "never",
    },
    {
      title: "Data Consegna",
      field: "dat_consegna",
      type: "date",
      align: "center",
      validate: ({ dat_consegna }) => isNotEmpty(dat_consegna),
    },
    {
      title: "Cliente",
      field: "des_cliente",
      type: "string",
      align: "center",
      validate: ({ des_cliente }) => isNotEmpty(des_cliente),
    },
    {
      title: "Codice Materia Prima",
      field: "cod_materia_prima",
      type: "string",
      align: "center",
      initialEditValue: "",
      validate: ({ cod_materia_prima }) => isNotEmpty(cod_materia_prima),
    },
    {
      title: "Materia Prima Necessaria",
      field: "qta_materia_prima_necessaria",
      type: "numeric",
      align: "center",
      initialEditValue: 0,
      validate: ({ qta_materia_prima_necessaria }) =>
        isInteger(qta_materia_prima_necessaria),
    },
    {
      title: "Data Arrivo Materia Prima",
      field: "dat_arrivo_materia_prima",
      type: "date",
      align: "center",
      initialEditValue: new Date(),
    },
    {
      title: "Stato",
      field: "flg_attivo",
      lookup: { true: "Attivo", false: "Non Attivo" },
      align: "center",
      validate: ({ flg_attivo }) => isNotEmpty(flg_attivo),
    },
  ]);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchContext.authAxios
      .get(`${endpoint}`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    fetchContext.authAxios
      .get(`/api/v1/lista_macchine`)
      .then((response) => {
        const newRisorse = {};
        response.data.forEach((elem) => {
          newRisorse[elem.cod_risorsa] = elem.cod_risorsa;
        });
        const newColumns = _.cloneDeep(columns);
        let index = newColumns.findIndex(
          (elem) => elem.field === "cod_risorsa_prevista"
        );
        newColumns[index].lookup = newRisorse;
        setColumns(newColumns);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <AuthorizedTable
        resource={resource}
        title={title}
        columns={columns}
        data={data}
        isLoading={isLoading}
        editable={{
          onRowUpdate: (newData, rest) => {
            return new Promise((resolve, reject) => {
              const { tableData, ...oldData } = rest;
              if (_.isEqual(newData, oldData) || hasNullValues(newData)) {
                resolve();
              } else {
                fetchContext.authAxios
                  .put(`${endpoint}`, {
                    ...newData,
                  })
                  .then((response) => {
                    if (response.data) {
                      return response.data;
                    }
                  })
                  .then((row) => {
                    const dataUpdate = _.cloneDeep(data);
                    dataUpdate[tableData.id] = row;
                    setData([...dataUpdate]);
                    resolve();
                  })
                  .catch((error) => {
                    console.log(error);
                    reject();
                  });
              }
            });
          },
          onRowAdd: (newData) => {
            const currentDate = new Date();
            newData.id_utente = authContext.getId();
            newData.dat_inserimento = `${currentDate.getDay()}-${currentDate.getMonth()}-${currentDate.getFullYear()}`;
            return new Promise((resolve, reject) => {
              if (hasNullValues(newData)) {
                reject();
              } else {
                fetchContext.authAxios
                  .post(`${endpoint}`, {
                    ...newData,
                    cod_ordine: `${simulationPrefix}${newData.cod_ordine}`,
                  })
                  .then((response) => {
                    if (response.data) {
                      return response.data;
                    }
                  })
                  .then((row) => {
                    const dataUpdate = _.cloneDeep(data);
                    dataUpdate.push(row);
                    setData([...dataUpdate]);
                    resolve();
                  })
                  .catch((error) => {
                    if (error.response) {
                      // Client recieved an error response (5xx, 4xx)
                      alert(error.response.data.msg);
                    } else if (error.request) {
                      // Client never recieved a response, or request never left
                    } else {
                      // Anything else
                    }
                    reject();
                  });
              }
            });
          },
          onRowDelete: (oldData) => {
            return new Promise((resolve, reject) => {
              fetchContext.authAxios
                .delete(`${endpoint}`, {
                  data: {
                    ...oldData,
                  },
                })
                .then((response) => {
                  if (response.data) {
                    return response.data;
                  }
                })
                .then((row) => {
                  const dataUpdate = _.cloneDeep(data).filter(
                    (elem) => elem.cod_ordine !== row.cod_ordine
                  );
                  setData([...dataUpdate]);
                  resolve();
                })
                .catch((error) => {
                  if (error.response) {
                    // Client recieved an error response (5xx, 4xx)
                    alert(error.response.data.msg);
                  } else if (error.request) {
                    // Client never recieved a response, or request never left
                  } else {
                    // Anything else
                  }
                  reject();
                });
            });
          },
        }}
      />
    </>
  );
};

export default OrdiniSimulati;
