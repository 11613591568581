import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

import { library } from "@fortawesome/fontawesome-svg-core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faTachometerAlt,
  faCog,
  faTable,
  faWrench,
  faLaughWink,
  faUserCircle,
  faUserCog,
  faSignOutAlt,
  faSlidersH,
  faHistory,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import Can from "./common/Can";

//SVG Icon Tecnoform
import { fatecnoform } from "../utils/constants";
library.add(fatecnoform);

const sections = [
  {
    navItems: [
      {
        label: "KPI",
        path: "home",
        icon: faChartLine,
        policy: "kpi:visit",
      },
    ],
  },
  {
    navItems: [
      {
        label: "Pianificazione Corrente",
        path: "current_planning",
        icon: faTachometerAlt,
        policy: "current_planning:visit",
      },
      {
        label: "Lista Run",
        path: "run_list",
        icon: faTable,
        policy: "run_list:visit",
      },
      {
        label: "Inserimento Dati",
        path: "data_entry",
        icon: faSlidersH,
        policy: "data_entry:visit",
      },
    ],
  },
  {
    navItems: [
      {
        label: "Registro Inserimento Dati",
        path: "data_entry_log",
        icon: faHistory,
        policy: "data_entry_log:visit",
      },
    ],
  },
  {
    navItems: [
      {
        label: "Help",
        path: "help",
        icon: faQuestionCircle,
        policy: "profile:visit",
      },
      {
        label: "Profilo",
        path: "profile",
        icon: faUserCircle,
        policy: "profile:visit",
      },
      {
        label: "Admin",
        path: "admin",
        icon: faUserCog,
        policy: "admin:visit",
      },
    ],
  },
];

const NavItem = ({ navItem }) => {
  const location = useLocation();
  const isCurrentRoute = location.pathname === `/${navItem.path}`;

  return (
    <Link to={`/${navItem.path}`} className="nav-link">
      <>
        <FontAwesomeIcon icon={navItem.icon} style={{ marginRIght: "10px" }} />
        <span style={{ marginLeft: "10px" }}>{navItem.label}</span>
      </>
    </Link>
  );
};

const NavItemContainer = ({ children }) => (
  <div className="nav-item">{children}</div>
);

const SectionContainer = ({ children }) => (
  <>
    <hr className="sidebar-divider" />
    {children}
  </>
);

const NavBrand = ({ text, version, icon }) => (
  <Link
    className="sidebar-brand d-flex align-items-center justify-content-center"
    to="/kpi"
  >
    <div className="sidebar-brand-icon rotate-n-15">
      <FontAwesomeIcon
        icon="tecnoform"
        flip="vertical"
        pull="left"
        transform={{ rotate: -15 }}
        size="2x"
      />
    </div>
    <div className="sidebar-brand-text mx-2">{text}</div>
  </Link>
);

const Logout = ({ onClick }) => (
  <a href="#" className="nav-link" onClick={() => onClick()}>
    <>
      <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRIght: "10px" }} />
      <span style={{ marginLeft: "10px" }}>Logout</span>
    </>
  </a>
);

const Sidebar = () => {
  const auth = useContext(AuthContext);
  const { role } = auth.authState.userInfo;

  return (
    <section
      className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion sidebar-toggle"
      style={{
        backgroundColor: "rgb(0,71,92)",
        backgroundImage: "url()",
        height: "100%",
      }}
    >
      <div>
        <NavBrand
          text="Tecnoform SuperScheduler"
          version={0}
          icon="https://www.tecnoform.net/images/favicon.png"
        />
      </div>
      <div>
        {sections.map((section, i) => (
          <SectionContainer key={i}>
            <div>
              {section.navItems.map((navItem, j) => (
                <NavItemContainer key={j}>
                  <Can
                    perform={navItem.policy}
                    yes={() => <NavItem navItem={navItem} />}
                  />
                </NavItemContainer>
              ))}
            </div>
          </SectionContainer>
        ))}
        <NavItemContainer>
          <Logout onClick={auth.logout} />
        </NavItemContainer>
      </div>
    </section>
  );
};

export default Sidebar;
