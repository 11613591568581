import React, { useContext } from "react";
import GanttContainer from "../components/GanttContainer";

const RunDetail = (props) => {
  const { runId } = props.computedMatch.params;
  return (
    <>
      {/* <h3>{authContext.isAuthenticated() ? "true" : "false"}</h3> */}
      <div className="container-fluid">{<GanttContainer runId={runId} />}</div>
    </>
  );
};

export default RunDetail;
