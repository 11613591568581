import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

const Can = ({ perform, data, yes, no, ...other }) => {
  const authContext = useContext(AuthContext);
  return authContext.hasPolicy(perform, data) ? yes(other) : no(other);
};

Can.defaultProps = {
  no: () => null,
};

export default Can;
