import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { BrowserRouter } from "react-router-dom";
// import { Provider } from "unstated";
import { hotjar } from "react-hotjar";

import "bootstrap/dist/css/bootstrap.min.css";
import "startbootstrap-sb-admin-2/css/sb-admin-2.css";

// ReactDOM.render(
//   // <Provider>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>,
//   // </Provider>,
//   document.getElementById("root")
// );

hotjar.initialize(2405019, 6);

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
