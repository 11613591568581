import React, { useEffect, useState } from "react";

import { Form } from "react-bootstrap";
import CircleButton from "../common/CircleButton";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import Can from "../common/Can";

import categories from "../../mock/CategoriesMock";

const InputNote = ({ note, handleSubmit, handleCancel }) => {
  const formik = useFormik({
    initialValues: {
      noteText: note ? note.des_message : "",
      noteCategory: note ? note.des_tipologia : "",
      noteTool: "",
      des_cliente: note ? !!note.des_cliente : false,
      cod_ordine: note ? !!note.cod_ordine : true,
      cod_parte: note ? !!note.cod_parte : false,
      cod_risorsa: note ? !!note.cod_risorsa : false,
      cod_campana: note ? !!note.cod_campana : false,
      cod_fustella: note ? !!note.cod_fustella : false,
      cod_tassello: note ? !!note.cod_tassello : false,
      cod_inserto: note ? !!note.cod_inserto : false,
    },
    validationSchema: Yup.object().shape({
      noteText: Yup.string().required("Campo richiesto"),
      noteCategory: Yup.mixed()
        .oneOf(categories.map((category) => category.tag))
        .required("Seleziona una categoria"),
      noteTool: Yup.mixed(),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const [isMaintenanceSelected, setIsMaintenanceSelected] = useState(false);
  useEffect(() => {
    const category = categories.find(
      (category) => category.tag === formik.values.noteCategory
    );
    if (
      category &&
      (category.tag === "maintenance" || category.tag === "maintenance_done")
    ) {
      setIsMaintenanceSelected(true);
    } else {
      setIsMaintenanceSelected(false);
    }
  }, [formik.values.noteCategory]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <select
        className="custom-select"
        name="noteCategory"
        type="text"
        onChange={formik.handleChange}
        value={formik.values.noteCategory}
      >
        <option label="Seleziona Tipo Nota.." disabled />
        {categories.map((category, i) => {
          return <CategoryOption key={i} category={category} />;
        })}
      </select>

      {formik.errors.noteCategory && formik.touched.noteCategory ? (
        <span className="text-danger">{formik.errors.noteCategory}</span>
      ) : null}

      <div>
        <Form.Check
          inline
          label="Cliente"
          type="checkbox"
          id="des_cliente"
          name="des_cliente"
          checked={formik.values.des_cliente}
          onChange={formik.handleChange}
        />
        <Form.Check
          inline
          label="Ordine"
          type="checkbox"
          id="cod_ordine"
          name="cod_ordine"
          checked={formik.values.cod_ordine}
          onChange={formik.handleChange}
        />
        <Form.Check
          inline
          label="Articolo"
          type="checkbox"
          id="cod_parte"
          name="cod_parte"
          checked={formik.values.cod_parte}
          onChange={formik.handleChange}
        />
        <Form.Check
          inline
          label="Risorsa"
          type="checkbox"
          id="cod_risorsa"
          name="cod_risorsa"
          checked={formik.values.cod_risorsa}
          onChange={formik.handleChange}
        />
      </div>
      {isMaintenanceSelected && (
        <div>
          <Form.Check
            inline
            label="Campana"
            type="checkbox"
            id="cod_campana"
            name="cod_campana"
            checked={formik.values.cod_campana}
            onChange={formik.handleChange}
          />
          <Form.Check
            inline
            label="Fustella"
            type="checkbox"
            id="cod_fustella"
            name="cod_fustella"
            checked={formik.values.cod_fustella}
            onChange={formik.handleChange}
          />
          <Form.Check
            inline
            label="Tassello"
            type="checkbox"
            id="cod_tassello"
            name="cod_tassello"
            checked={formik.values.cod_tassello}
            onChange={formik.handleChange}
          />
          <Form.Check
            inline
            label="Inserto"
            type="checkbox"
            id="cod_inserto"
            name="cod_inserto"
            checked={formik.values.cod_inserto}
            onChange={formik.handleChange}
          />
        </div>
      )}

      <textarea
        className="form-control"
        id="noteText"
        name="noteText"
        type="text"
        onChange={formik.handleChange}
        value={formik.values.noteText}
      />

      {formik.errors.noteText && formik.touched.noteText ? (
        <span className="text-danger">{formik.errors.noteText}</span>
      ) : null}

      <div>
        <CircleButton
          className="btn-sm btn-success m-1"
          type="submit"
          icon={faCheck}
        />
        <CircleButton
          className="btn-sm btn-danger m-1"
          icon={faTimes}
          onClick={handleCancel}
        />
      </div>
    </form>
  );
};

const CategoryOption = ({ category }) => {
  return (
    <Can
      perform={`note_${category.tag}:edit`}
      yes={() => <option value={category.tag} label={category.value} />}
    />
  );
};

export default InputNote;
