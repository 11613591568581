let tasks = [];

const randomDate = (start, end) => {
  return new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  );
};

for (let i = 0; i < 10; i++) {
  let start =
    i < 5
      ? randomDate(new Date(2019, 0, 1), new Date(2020, 0, 1))
      : randomDate(new Date(2020, 0, 1), new Date());
  let end =
    i < 5
      ? randomDate(start, new Date(2020, 0, 1))
      : randomDate(start, new Date());

  tasks.push({
    id: `${i}`,
    category: `Pressa ${i % 5}`,
    start: start.toISOString(),
    end: end.toISOString(),
    color: "#" + Math.floor(Math.random() * 16777215).toString(16),
    article: `article ${i}`,
    stamp: `stamp ${i}`,
    client: `client ${i}`,
  });
}

export default tasks;
