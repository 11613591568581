import React, { useContext, useEffect, useState } from "react";
import { FetchContext } from "../../context/FetchContext";
import { AuthContext } from "../../context/AuthContext";
import AuthorizedTable from "../common/AuthorizedTable";
import _ from "lodash";
import { isInteger, isNotEmpty } from "./utils";

const resource = "alto_vendenti";
const title = "Altovendenti";
const endpoint = `/api/v1/alto_vendenti`;

const AltoVendenti = () => {
  const fetchContext = useContext(FetchContext);
  const [isLoading, setIsLoading] = useState(true);
  const [columns] = useState([
    {
      title: "Codice Articolo",
      field: "cod_parte",
      type: "string",
      align: "center",
      editable: "onAdd",
      validate: ({ cod_parte }) => isNotEmpty(cod_parte),
    },
    {
      title: "Tranche",
      field: "id_tranche",
      type: "numeric",
      align: "center",
      editable: "onAdd",
      validate: ({ id_tranche }) => isInteger(id_tranche) && id_tranche > 0,
    },
    {
      title: "Data Inizio Produzione",
      field: "dat_inizio_produzione",
      type: "date",
      align: "center",
      editable: "always"
    },
    {
      title: "Data Fine Produzione",
      field: "dat_fine_produzione",
      type: "date",
      align: "center",
      editable: "always"
    },
    {
      title: "Pezzi Ordinati",
      field: "val_pezzi_ordinati",
      type: "numeric",
      align: "center",
      editable: "always",
      validate: ({ val_pezzi_ordinati }) => !val_pezzi_ordinati || isInteger(val_pezzi_ordinati),
    },
    {
      title: "Codice Risorsa",
      field: "cod_risorsa",
      type: "string",
      align: "center",
      editable: "always",
    },
    {
      title: "Stato",
      field: "flg_attivo",
      lookup: { true: "Attivo", false: "Non Attivo" },
      align: "center",
      editable: "onUpdate"
    },
  ]);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchContext.authAxios
      .get(`${endpoint}`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <AuthorizedTable
        resource={resource}
        title={title}
        columns={columns}
        data={data}
        isLoading={isLoading}
        editable={{
          onRowUpdate: (newData, rest) => {
            return new Promise((resolve, reject) => {
              const { tableData, ...oldData } = rest;
              if (_.isEqual(newData, oldData)) {
                resolve();
              } else {
                fetchContext.authAxios
                  .put(`${endpoint}`, {
                    ...newData,
                  })
                  .then((response) => {
                    if (response.data) {
                      return response.data;
                    }
                  })
                  .then((row) => {
                    const dataUpdate = _.cloneDeep(data);
                    dataUpdate[tableData.id] = row;
                    setData([...dataUpdate]);
                    resolve();
                  })
                  .catch((error) => {
                    console.log(error);
                    reject();
                  });
              }
            });
          },
          onRowAdd: (newData) => {
            return new Promise((resolve, reject) => {
              fetchContext.authAxios
                .post(`${endpoint}`, {
                  ...newData
                })
                .then((response) => {
                  if (response.data) {
                    return response.data;
                  }
                })
                .then((row) => {
                  const dataUpdate = _.cloneDeep(data);
                  dataUpdate.push(row);
                  setData([...dataUpdate]);
                  resolve();
                })
                .catch((error) => {
                  if (error.response) {
                    // Client recieved an error response (5xx, 4xx)
                    alert(error.response.data.msg);
                  } else if (error.request) {
                    // Client never recieved a response, or request never left
                  } else {
                    // Anything else
                  }
                  console.log(error);
                  reject();
                });
            });
          },
          onRowDelete: (oldData) => {
            return new Promise((resolve, reject) => {
              fetchContext.authAxios
                .delete(`${endpoint}`, {
                  data: {
                    ...oldData,
                  },
                })
                .then((response) => {
                  if (response.data) {
                    return response.data;
                  }
                })
                .then((row) => {
                  const dataUpdate = _.cloneDeep(data).filter(
                    (elem) => elem.cod_parte !== row.cod_parte || elem.id_tranche !== row.id_tranche
                  );
                  setData([...dataUpdate]);
                  resolve();
                })
                .catch((error) => {
                  if (error.response) {
                    // Client recieved an error response (5xx, 4xx)
                    alert(error.response.data.msg);
                  } else if (error.request) {
                    // Client never recieved a response, or request never left
                  } else {
                    // Anything else
                  }
                  console.log(error);
                  reject();
                });
            });
          },
        }}
      />
    </>
  );
};

export default AltoVendenti;
