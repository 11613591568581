import React, { createContext, useEffect, useState } from "react";

const AppContext = createContext();
const { Provider } = AppContext;

const AppProvider = ({ children }) => {
  const [orders, setOrders] = useState(null);
  const [notes, setNotes] = useState([]);
  const [categories, setCategories] = useState(null);
  const [tools, setTools] = useState(null);

  const mergeStates = (newState, oldState) => {
    // Gets in input two lists and makes the merge with distinct items per id
    if (oldState != null) {
      let stateUnion = [...newState, ...oldState];
      console.log("stateUnion", stateUnion);
      let set = new Set();
      return stateUnion.filter((item) => {
        if (!set.has(item.id)) {
          set.add(item.id);
          return true;
        }
        return false;
      }, set);
    }
    return newState;
  };

  const setOrdersState = (inputOrders) => {
    const newOrders = mergeStates(inputOrders, orders);
    setOrders(newOrders);
  };

  const deleteOrder = (order) => {
    const newOrders = orders.filter((elem) => elem.id !== order.id);
    setOrders(newOrders);
  };

  const setNotesState = (inputNotes) => {
    const newNotes = mergeStates(inputNotes, notes);
    setNotes(newNotes);
    console.log("setNewNotes", notes);
  };

  const deleteNoteState = (note) => {
    console.log("deleteNoteState", notes);
    const newNotes = notes.filter((elem) => elem.id !== note.id);
    setNotes(newNotes);
  };

  const setCategoriesState = (inputCategories) => {
    const newCategories = mergeStates(inputCategories, categories);
    setCategories(newCategories);
  };

  const deleteCategory = (category) => {
    const newCategories = categories.filter((elem) => elem.id !== category.id);
    setCategories(newCategories);
  };

  const setToolsState = (inputTools) => {
    const newTools = mergeStates(inputTools, tools);
    setTools(newTools);
  };

  const deleteToolState = (tool) => {
    const newTools = tools.filter((elem) => elem.id !== tool.id);
    setTools(newTools);
  };

  // useEffect(() => {
  //   console.log("appContext tools:", tools);
  // }, [tools])

  return (
    <Provider
      value={{
        orders,
        setOrders: (orders) => setOrdersState(orders),
        deleteOrder,
        notes,
        setNotes: (notes) => setNotesState(notes),
        deleteNote: (note) => deleteNoteState(note),
        categories,
        setCategories: (categories) => setCategoriesState(categories),
        deleteCategory,
        tools,
        setTools: (tools) => setToolsState(tools),
        deleteTool: (tool) => deleteToolState(tool),
      }}
    >
      {children}
    </Provider>
  );
};

export { AppContext, AppProvider };
