import React from "react";

import Sidebar from "./components/Sidebar";

const AppShell = ({ children }) => {
  return (
    <>
      <div id="wrapper">
        <div>
          <Sidebar />
        </div>
        <div id="content-wrapper" className="d-flax flex-column">
          {children}
        </div>
      </div>
    </>
  );
};

export default AppShell;
