import axios from "axios";
import FetchMock from "../mock/FetchMock";
import { API_URL } from "./constants.js";
const publicFetch = axios.create({
  baseURL: API_URL,
});

// Remove this if you want to contact the backend API
// FetchMock(publicFetch);

export { publicFetch };
