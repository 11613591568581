// Documentation link: https://github.com/ctimmerm/axios-mock-adapter

import MockAdapter from "axios-mock-adapter";
import { tokens } from "./../utils/constants";
import { users, get_last_counter } from "./UsersMock";
import tasks from "./TasksMock";
import notes from "./NotesMock";
import tools from "./ToolsMock";
import ordini from "./OrdiniMock";

let noteIdCount = notes.length - 1;

const FetchMock = (axiosClient) => {
  const mock = new MockAdapter(axiosClient, { delayResponse: 0 });

  mock.onPost("api/login").reply((config) => {
    const data = JSON.parse(config.data);
    console.log("login list");
    console.log(users);
    const idx = users.findIndex((user) => {
      return user.email === data.email && user.password === data.password;
    });

    if (idx < 0) {
      return [
        400,
        {
          message: "Wrong email or password",
        },
      ];
    }

    const user = users[idx];
    console.log("User policies", user.policies);

    const stringifyDynamicPolicies = (policies) => {
      for (let key in policies.dynamic) {
        policies.dynamic[key] = "" + policies.dynamic[key];
      }
    };

    stringifyDynamicPolicies(user.policies);

    return [
      200,
      {
        token: "shoisrhglslsjljsflbjlsji23y9rhgo984yu340",
        userInfo: {
          id: user.id,
          name: user.name,
          role: user.role,
          policies: user.policies,
          email: user.email,
        },
        expiresAt: new Date().getTime() + 60 * 60 * 1000,
        message: "Successful Login",
      },
    ];
  });

  mock.onGet("/users").reply((config) => {
    return [
      200,
      {
        users: users,
      },
    ];
  });

  mock.onGet("/kpi").reply((config) => {
    return [200, ["first kpi", "second kpi", "third kpi"]];
  });

  mock.onGet("/tasks").reply((config) => {
    let result = [];
    tasks.forEach((task) => {
      result.push({
        ...task,
        notes: notes.filter((note) => note.taskId === task.id),
      });
    });

    return [200, result];
  });

  mock.onGet(/\/tasks\/\d+\/notes/).reply((config) => {
    const taskId = config.url.split("/")[1];
    const results = notes.filter((note) => note.taskId === taskId);

    return [200, results];
  });

  mock.onGet(/\/tasks\/\d+\/tools/).reply((config) => {
    const taskId = config.url.split("/")[1];
    const results = tools.filter((tool) => tool.orderIds.includes(taskId));

    return [200, results];
  });

  mock.onPost("/note").reply((config) => {
    const data = JSON.parse(config.data);

    noteIdCount++;
    let newNote = {
      id: `${noteIdCount}`,
      authorId: data.authorId,
      author: data.author,
      text: data.text,
      taskId: data.taskId,
      timestamp: new Date(),
      category: data.category,
    };
    notes.push(newNote);

    notes.sort((a, b) => b.id - a.id);

    return [200, newNote];
  });

  mock.onDelete(/\/notes\/\d+/).reply((config) => {
    const noteId = config.url.split("/")[1];
    const index = notes.findIndex((note) => note.id === noteId);
    const removedNote = { ...notes[index] };
    notes.splice(index, 1);

    return [200, removedNote];
  });

  mock.onPut("/note").reply((config) => {
    const data = JSON.parse(config.data);
    const index = notes.findIndex((note) => note.id === data.id);
    const newNote = {
      id: data.id,
      text: data.text,
      authorId: data.authorId,
      author: data.author,
      taskId: data.taskId,
      timestamp: new Date(),
      category: data.category,
    };
    notes[index] = newNote;

    return [200, newNote];
  });

  mock.onPost("/auth/addUser").reply((config) => {
    let data = JSON.parse(config.data);
    if (data["email"] == "ciao@gmail.com")
      return [400, { error: "prova errore" }];
    let token = generate_token(15);
    tokens.push({ token: token, email: data["email"], role: data["role"] });
    return [200, { token: token }];
  });

  mock.onGet("/auth/fetchUsers").reply((config) => {
    return [200, users];
  });

  mock.onPost("/register").reply((config) => {
    let data = JSON.parse(config.data);
    let token = tokens.filter((token) => {
      if (token["email"] == data["email"] && token["token"] == data["token"])
        return token;
    });
    if (token != null) {
      data["role"] = token[0]["role"];
      data["_id"] = get_last_counter() + 1;
      data["policies"] = [];
      users.push(data);
      return [200];
    } else {
      return [404, { errorr: "token non valido" }];
    }
  });

  mock.onPost("/auth/modifyUsers").reply((config) => {
    let data = JSON.parse(config.data);
    if (data["usersToDelete"] != null) {
      let userIndex = users.findIndex((user) => {
        return user.email == data["usersToDelete"][0]["email"];
      });
      users.splice(userIndex, 1);
    }
    return [200];
  });

  mock.onPost("/auth/modifyPassword").reply((config) => {
    let data = JSON.parse(config.data);
    let userIndex = users.findIndex((user) => {
      return (
        user.email == data["email"] &&
        user.id == data["id"] &&
        user.password == data["currentPassword"]
      );
    });
    users[userIndex].password = data["newPassword"];
    if (userIndex == null || userIndex == undefined) {
      return [401];
    } else {
      return [200];
    }
  });

  mock.onGet("/ordini").reply((config) => {
    return [
      200,
      {
        ordini: ordini,
      },
    ];
  });

  mock.onPost("/ordini").reply((config) => {
    let data = JSON.parse(config.data);
    const cod_ordine = data["row"]["Codice Ordine"];
    const ordine = ordini.find((elem) => elem["Codice Ordine"] == cod_ordine);
    let fg_acconto = data["row"]["Flag Acconto"];
    if (
      ordine["Flag Acconto"] != fg_acconto &&
      (fg_acconto == "Si" || fg_acconto == "SI" || fg_acconto == "si")
    ) {
      ordini[ordine] = data["row"];
      return [200, { status: "form" }];
    } else {
      return [200, { status: "success" }];
    }
  });

  mock.onPost("/acconto_ordine").reply((config) => {
    let data = JSON.parse(config.data);
    console.log("acconti ordine", data);
    return [200, { status: "success" }];
  });
};

function generate_token(length) {
  //edit the token allowed characters
  var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split(
    ""
  );
  var b = [];
  for (var i = 0; i < length; i++) {
    var j = (Math.random() * (a.length - 1)).toFixed(0);
    b[i] = a[j];
  }
  return b.join("");
}

export default FetchMock;
