const isInteger = (element) => {
  return !Number.isInteger(element)
    ? { isValid: false, helperText: "Richiesto un intero" }
    : true;
};

const isNotEmpty = (element) => {
  return element == undefined ||
    typeof element == undefined ||
    typeof element == null ||
    (element != false && element == "") ||
    element == "None"
    ? { isValid: false, helperText: "Campo Obbligatorio" }
    : true;
};

const hasNullValues = (data) => {
  let isDataOk = false;
  Object.keys(data).forEach((key) => {
    if (data[key] === null) isDataOk = true;
  });
  return isDataOk;
};

export { isInteger, isNotEmpty, hasNullValues };
