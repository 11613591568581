import React from "react";
import Note from "./../Note/Note";

const NoteList = ({ notes, task, onDelete, onEdit }) => {
  return (
    <ul className="list-group">
      {notes.map((note, i) => (
        <li key={i} className="list-group-item py-1 pr-1">
          <Note note={note} task={task} onDelete={onDelete} onEdit={onEdit} />
        </li>
      ))}
    </ul>
  );
};

export default NoteList;
