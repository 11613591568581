import React, { useContext, useEffect, useState } from "react";
import AuthorizedTable from "../common/AuthorizedTable";
import { FetchContext } from "../../context/FetchContext";
import _ from "lodash";
import { isInteger, isNotEmpty } from "./utils";

const resource = "lista_macchine";
const title = "Lista Macchine";
const endpoint = `/api/v1/lista_macchine`;

const ListaMacchine = () => {
  const fetchContext = useContext(FetchContext);
  const [isLoading, setIsLoading] = useState(true);
  const [columns] = useState([
    {
      title: "Codice Risorsa",
      field: "cod_risorsa",
      type: "string",
      align: "center",
      editable: "never",
    },
    {
      title: "Stato",
      field: "flg_attiva",
      lookup: { true: "Attiva", false: "Ferma" },
      align: "center",
    },
    {
      title: "Tipologia",
      field: "cod_tipologia",
      lookup: { pinze: "pinze", catene: "catene" },
      align: "center",
    },
    {
      title: "Matricola",
      field: "cod_matricola",
      type: "string",
      align: "center",
      validate: ({ cod_matricola }) => isNotEmpty(cod_matricola),
    },
    {
      title: "Anno",
      field: "val_anno_acquisto",
      type: "string",
      align: "center",
      validate: ({ val_anno_acquisto }) => isNotEmpty(val_anno_acquisto),
    },
    {
      title: "Reparto",
      field: "cod_reparto",
      lookup: { rep1: "rep1", rep3: "rep3" },
      align: "center",
    },
    {
      title: "Lunghezza Massima",
      field: "val_lunghezza_max",
      type: "numeric",
      align: "center",
      validate: ({ val_lunghezza_max }) => isInteger(val_lunghezza_max),
    },
    {
      title: "Larghezza Massima",
      field: "val_larghezza_max",
      type: "numeric",
      align: "center",
      validate: ({ val_larghezza_max }) => isInteger(val_larghezza_max),
    },
    {
      title: "Pallone",
      field: "flg_pallone",
      lookup: { true: "Si", false: "No" },
      align: "center",
    },
    {
      title: "Impilatore",
      field: "cod_impilatore",
      lookup: { "A-B": "A-B", "A-B-C": "A-B-C", None: "Nessuno" },
      align: "center",
    },
    {
      title: "Velocità Minima",
      field: "val_vel_min",
      type: "numeric",
      align: "center",
      validate: ({ val_vel_min }) => isInteger(val_vel_min),
    },
    {
      title: "Velocità Massima",
      field: "val_vel_max",
      type: "numeric",
      align: "center",
      validate: ({ val_vel_max }) => isInteger(val_vel_max),
    },
    {
      title: "Costo",
      field: "val_costo",
      type: "numeric",
      align: "center",
      validate: ({ val_costo }) => isInteger(val_costo),
    },
  ]);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchContext.authAxios
      .get(`${endpoint}`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <AuthorizedTable
      resource={resource}
      title={title}
      columns={columns}
      data={data}
      isLoading={isLoading}
      editable={{
        onRowUpdate: (newData, rest) => {
          return new Promise((resolve, reject) => {
            const { tableData, ...oldData } = rest;
            if (_.isEqual(newData, oldData)) {
              resolve();
            } else {
              fetchContext.authAxios
                .put(`${endpoint}`, {
                  ...newData,
                })
                .then((response) => {
                  if (response.data) {
                    return response.data;
                  }
                })
                .then((row) => {
                  const dataUpdate = _.cloneDeep(data);
                  dataUpdate[tableData.id] = row;
                  setData([...dataUpdate]);
                  resolve();
                })
                .catch((error) => {
                  console.log(error);
                  reject();
                });
            }
          });
        },
      }}
    />
  );
};

export default ListaMacchine;
