import React, { useContext, useEffect, useState } from "react";
import MaterialTable from "material-table";
import { FetchContext } from "../context/FetchContext";
import _ from "lodash";
import { Container, Jumbotron } from "react-bootstrap";
import { PatchedPagination } from "../utils/patchedPagination";

const endpoint = `/api/v1/log_data_entry`;

const DataEntryLog = () => {
  const [data, setData] = useState([]);
  const fetchContext = useContext(FetchContext);
  const [isFetchingData, setIsFetchingData] = useState(true);

  const fetchData = async () => {
    setIsFetchingData(true);
    try {
      await fetchContext.authAxios.get(`${endpoint}`).then((_) => {
        setData(_.data);
      });

      setIsFetchingData(false);
    } catch (err) {
      console.log(err);
      setIsFetchingData(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container fluid className={"mt-4"}>
      <Jumbotron className="py-3">
        <h1>Registro Inserimento Dati</h1>
      </Jumbotron>
      <MaterialTable
        components={{
          Pagination: PatchedPagination,
        }}
        title="Registro Inserimento Dati"
        columns={[
          { title: "Id", field: "id" },
          { title: "Data", field: "action_tstamp" },
          { title: "Tabella", field: "tabella" },
          { title: "Username", field: "email" },
          { title: "Azione", field: "azione" },
          { title: "Query", field: "query" },
          { title: "Campi", field: "campi" },
        ]}
        data={data}
        isLoading={isFetchingData}
        options={{
          filtering: true,
        }}
      />
    </Container>
  );
};

export default DataEntryLog;
