import React, { useContext, useEffect } from "react";
import MaterialTable from "material-table";
import { AuthContext } from "../../context/AuthContext";
import { PatchedPagination } from "../../utils/patchedPagination";

const AuthorizedTable = ({ resource, ...rest }) => {
  const authContext = useContext(AuthContext);

  const canEditRow = authContext.hasPolicy(`${resource}:edit`, null);
  const canDeleteRow = authContext.hasPolicy(`${resource}:delete`, null);
  const canAddRow = authContext.hasPolicy(`${resource}:add`, null);

  const { editable, options } = rest;
  const isEditable = editable.isEditable ? editable.isEditable() : true;
  const isEditHidden = editable.isEditHidden ? editable.isEditHidden() : false;
  const isDeletable = editable.isDeletable ? editable.isDeletable() : true;
  const isDeleteHidden = editable.isDeleteHidden
    ? editable.isDeleteHidden()
    : false;

  const authorizedEditable = {
    ...editable,
    isEditable: () => (canEditRow || canAddRow ? isEditable : false),
    isEditHidden: () => (canEditRow ? isEditHidden : true),
    isDeletable: () => (canDeleteRow ? isDeletable : false),
    isDeleteHidden: () => (canDeleteRow ? isDeleteHidden : true),
    onRowAdd: canAddRow ? editable.onRowAdd : null,
  };

  const authorizedOptions = options ? options : defaultOptions;
  return (
    <MaterialTable
      {...rest}
      components={{
        Pagination: PatchedPagination,
      }}
      options={{ ...authorizedOptions }}
      localization={{
        ...italianLocalization,
        header: {
          actions:
            canEditRow || canDeleteRow
              ? italianLocalization.header.actions
              : "",
        },
      }}
      editable={authorizedEditable}
    />
  );
};

const defaultOptions = {
  pageSize: 10,
  pageSizeOptions: [10, 20, 30],
  filtering: true,
};

const italianLocalization = {
  body: {
    emptyDataSourceMessage: "Nessun record da mostrare",
    addTooltip: "Aggiungi",
    deleteTooltip: "Elimina",
    editTooltip: "Modifica",
    filterRow: {
      filterTooltip: "Filtra",
    },
    editRow: {
      deleteText: "Sei sicuro di cancellare questa riga?",
      cancelTooltip: "Annulla",
      saveTooltip: "Salva",
    },
  },
  grouping: {
    placeholder: "Trascinare l'intestazione",
    groupedBy: "Raggruppato per:",
  },
  header: {
    actions: "Azioni",
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count}",
    labelRowsSelect: "righe",
    labelRowsPerPage: "Righe per pagina:",
    firstAriaLabel: "Prima pagina",
    firstTooltip: "Prima pagina",
    previousAriaLabel: "Pagina Precedente",
    previousTooltip: "Pagina Precedente",
    nextAriaLabel: "Pagina Successiva",
    nextTooltip: "Pagina Successiva",
    lastAriaLabel: "Ultima Pagina",
    lastTooltip: "Ultima Pagina",
  },
  toolbar: {
    addRemoveColumns: "Aggiungi o rimuovi colonne",
    nRowsSelected: "{0} righe selezionate",
    showColumnsTitle: "Mostra Colonne",
    showColumnsAriaLabel: "Mostra Colonne",
    exportTitle: "Esporta",
    exportAriaLabel: "Esporta",
    exportName: "Esporta in CSV",
    searchTooltip: "Cerca",
    searchPlaceholder: "Cerca",
  },
};

export default AuthorizedTable;
