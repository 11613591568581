import React, { useContext, useEffect, useState } from "react";
import AuthorizedTable from "../common/AuthorizedTable";
import { FetchContext } from "../../context/FetchContext";
import _ from "lodash";
import { isNotEmpty, isInteger } from "./utils";
import { compareLists } from "../../utils/functions";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Chip from "@material-ui/core/Chip";

const resource = "produzioni";
const title = "Produzioni";
const endpoint = `/api/v1/produzioni`;

const Produzioni = () => {
  const fetchContext = useContext(FetchContext);
  const [isLoading, setIsLoading] = useState(true);
  const [occurrencies, setOccurrencies] = useState("..");
  const [columns] = useState([
    {
      title: "Codice Articolo",
      field: "cod_parte",
      type: "string",
      align: "center",
      editable: "never",
    },
    {
      title: "Pose",
      field: "val_pose",
      type: "numeric",
      align: "center",
      editable: "never",
    },
    {
      title: "Categoria",
      field: "categories",
      type: "string",
      lookup: { flg_catene: "Catene", flg_pinze: "Pinze" },
      align: "center",
      validate: ({ categories }) => true,
      render: (rowData) => CategoriesList(rowData),
      editComponent: (props) => CategoriesEdit(props),
      customFilterAndSearch: (term, rowData) => {
        if (term.length === 0) {
          return true;
        }
        for (let i = 0; i < term.length; i++) {
          if (rowData.categories.includes(term[i])) {
            return true;
          }
        }
        return false;
      },
      customSort: (rowData_a, rowData_b) => {
        return compareLists(
          rowData_a.categories,
          rowData_b.categories,
          (a, b) => {
            if (a < b) return -1;
            if (a > b) return 1;
            if (a === b) return 0;
          }
        );
      },
    },
    {
      title: "Può andare sulla Risorsa 3",
      field: "flg_risorsa_3",
      lookup: { true: "Si", false: "No" },
      align: "center",
      validate: ({ flg_risorsa_3 }) =>
        flg_risorsa_3 === ""
          ? { isValid: false, helperText: "Campo Obbligatorio" }
          : true,
    },
    {
      title: "Lunghezza Campana",
      field: "val_lunghezza",
      type: "numeric",
      align: "center",
      placeholder: "(opzionale)",
      validate: ({ val_lunghezza }) =>
        !Number.isInteger(val_lunghezza)
          ? {
              isValid: false,
              helperText:
                "Richiesto un intero. Se valore non noto, inserisci 0.",
            }
          : true,
    },
    {
      title: "Larghezza Campana",
      field: "val_larghezza",
      type: "numeric",
      align: "center",
      validate: ({ val_larghezza }) =>
        !Number.isInteger(val_larghezza)
          ? {
              isValid: false,
              helperText:
                "Richiesto un intero. Se valore non noto, inserisci 0.",
            }
          : true,
    },
    {
      title: "Pallone Necessario",
      field: "flg_pallone",
      lookup: { true: "Si", false: "No" },
      align: "center",
      validate: ({ flg_pallone }) =>
        flg_pallone === ""
          ? { isValid: false, helperText: "Campo Obbligatorio" }
          : true,
    },
    {
      title: "Tipo di Impilaggio",
      field: "cod_impilatore",
      lookup: {
        "A-B": "A-B",
        "A-B-C": "A-B-C",
        normal: "Normale",
        manual: "Manuale",
        inbulk: "Alla Rinfusa",
      },
      align: "center",
      validate: ({ cod_impilatore }) =>
        cod_impilatore === ""
          ? { isValid: false, helperText: "Campo Obbligatorio" }
          : true,
    },
    {
      title: "Compilato",
      field: "flg_touch",
      type: "boolean",
      align: "center",
      defaultSort: "asc",
    },
  ]);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchContext.authAxios
      .get(`${endpoint}`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    fetchContext.authAxios
      .get(`/api/v1/touch?schema=lkp&table=${resource}`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setOccurrencies(data.occurrencies);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <AuthorizedTable
      resource={resource}
      title={`${title} (da compilare ${occurrencies})`}
      columns={columns}
      data={data}
      isLoading={isLoading}
      detailPanel={(rowData) => {
        return <DettaglioAttrezzature {...rowData} />;
      }}
      onRowClick={(event, rowData, togglePanel) => {
        return togglePanel();
      }}
      editable={{
        onRowUpdate: (newData, rest) => {
          return new Promise((resolve, reject) => {
            const { tableData, ...oldData } = rest;
            if (_.isEqual(newData, oldData)) {
              reject();
            } else {
              fetchContext.authAxios
                .post(`${endpoint}`, {
                  ...newData,
                })
                .then((response) => {
                  if (response.data) {
                    return response.data;
                  }
                })
                .then((row) => {
                  const dataUpdate = _.cloneDeep(data);
                  dataUpdate[tableData.id] = row;
                  setData([...dataUpdate]);
                  resolve();
                })
                .catch((error) => {
                  console.log(error);
                  reject();
                });
            }
          });
        },
      }}
    />
  );
};

const DettaglioAttrezzature = (rowData) => {
  const fetchContext = useContext(FetchContext);
  const [isFetching, setIsFetching] = useState(true);
  const [data, setData] = useState([]);

  const [columns] = useState([
    {
      title: "Categoria",
      field: "tipo",
      type: "string",
      editable: "never",
    },
    {
      title: "Codice",
      field: "cod_attrezzatura",
      type: "string",
      editable: "never",
    },
    {
      title: "Numero Copie",
      field: "val_copie",
      type: "numeric",
      editable: "never",
    },
  ]);

  useEffect(() => {
    fetchContext.authAxios
      .get(`api/v1/attrezzature?filter_cod_parte__eq=${rowData.cod_parte}`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, []);
  return (
    <AuthorizedTable
      resource={"attrezzature"}
      title={`Attrezzature per (${rowData.cod_parte}, ${rowData.val_pose})`}
      columns={columns}
      data={data}
      isLoading={isFetching}
      editable={{}}
      options={{
        pageSize: 2,
        pageSizeOptions: [2, 5],
      }}
    />
  );
};

const CategoriesList = (props) => {
  return (
    <div style={{ margin: "auto" }}>
      {props.categories.map((elem) => (
        <Chip style={{ margin: "2" }} key={elem} label={elem} />
      ))}
    </div>
  );
};

const CategoriesEdit = (props) => {
  return (
    <>
      <FormControl error={Boolean(props.error)}>
        <Select
          labelId="multiple-category-label"
          id="multiple-category"
          multiple
          value={props.value}
          onChange={(event) => props.onChange(event.target.value)}
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => (
            <div>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </div>
          )}
        >
          {Object.keys(props.columnDef.lookup).map((key) => (
            <MenuItem key={key} value={key}>
              {props.columnDef.lookup[key]}
            </MenuItem>
          ))}
        </Select>
        {Boolean(props.helperText) && (
          <FormHelperText>{props.helperText}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default Produzioni;
