import { result } from "lodash";

const compareResources = (a, b) => {
  /*
      Questa funzione dati due input all'interno di ordinamento restituisce
      -1 se a < b
      0 se a == b
      1 se a > b
    */
  const ordinamento = [
    "03",
    "04",
    "05",
    "06",
    "08",
    "011",
    "07",
    "010",
    "09",
    "012",
    "015",
    "014",
    "013",
  ];

  const aindex = ordinamento.findIndex((elem) => elem == a);
  const bindex = ordinamento.findIndex((elem) => elem == b);
  if (aindex < bindex) {
    return -1;
  }
  if (aindex > bindex) {
    return 1;
  }
  return 0;
};

const compareLists = (aarray, barray, compareFunc) => {
  /*
    Questo metodo compara due array di risorse aarray, bbarray
    e li ordina seguendo questa logica:
    [] < ["03"]
    ["03"] < ["03", "04"]
    ["03"] == ["03"]
    l'ordinamento del singolo elemento è definito in compareFunc
    */

  //arrays are both empty
  if (aarray.length == 0 && barray.length == 0) {
    return 0;
  }

  if (aarray.length == 0) {
    return -1;
  }

  if (barray.length == 0) {
    return 1;
  }
  aarray.sort(compareFunc);
  barray.sort(compareFunc);

  let resultInCaseOfEquality = null;
  let minimumLength = null;
  if (aarray.length < barray.length) {
    minimumLength = aarray.length;
    resultInCaseOfEquality = -1;
  } else if (aarray.length == barray.length) {
    minimumLength = aarray.length;
    resultInCaseOfEquality = 0;
  } else {
    // if aarray.length > barray.length
    minimumLength = barray.length;
    resultInCaseOfEquality = 1;
  }

  for (let i = 0; i < minimumLength; i++) {
    let comp = compareFunc(aarray[i], barray[i]);
    if (comp != 0) {
      return comp;
    }
  }
  return resultInCaseOfEquality;
};

export { compareResources, compareLists };
