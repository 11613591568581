import React, { createContext, useContext } from "react";
import axios from "axios";
import { AuthContext } from "./AuthContext";

import FetchMock from "../mock/FetchMock";

const FetchContext = createContext();
const { Provider } = FetchContext;

const FetchProvider = ({ children }) => {
  const authContext = useContext(AuthContext);

  const authAxios = axios.create({
    baseURL:
      process.env.NODE_ENV === "development" // React env variables docs: https://create-react-app.dev/docs/adding-custom-environment-variables/
        ? "http://localhost:5000"
        : "https://backend.scheduler.tecnoform.cloud.ammagamma.com",
  });

  // Remove this if you want to contact the backend API
  // FetchMock(authAxios);

  // Every asxios request has this configuration added before beening sent
  authAxios.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${authContext.authState.token}`;
      if (config.data) {
        config.data["user_id"] = authContext.getId();
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // // Every axios response is processed as before
  authAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const code = error && error.response ? error.response.status : 0;
      if (code === 401 || code === 403) {
        console.log("error code", code);
      }
      return Promise.reject(error);
    }
  );

  return (
    <Provider
      value={{
        authAxios,
      }}
    >
      {children}
    </Provider>
  );
};

export { FetchContext, FetchProvider };
