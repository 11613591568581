import React, { useContext, useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";

import * as Yup from "yup";
import GradientButton from "../components/common/GradientButton";
import { publicFetch } from "../utils/fetch";
import { AuthContext } from "../context/AuthContext";

const Login = () => {
  const authContext = useContext(AuthContext);
  const [loginLoading, setLoginLoading] = useState(false);
  const [redirectOnLogin, setRedirectOnLogin] = useState(false);
  const [signinSuccess, setSigninSuccess] = useState();
  const [signinError, setSigninError] = useState();

  useEffect(() => {
    if (authContext.isAuthenticated()) {
      setRedirectOnLogin(true);
    }
  }, []);

  useEffect(() => {
    console.log("redirect!", redirectOnLogin);
  }, [redirectOnLogin]);

  const submitCredentials = async (values, { setSubmitting }) => {
    setLoginLoading(true);
    const data_form = {
      email: values["email"],
      password: values["password"],
    };

    publicFetch
      .post(`api/v1/login`, data_form)
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
      .then((data) => {
        authContext.setAuthState(data);
        setSigninSuccess(true);
        setSigninError(null);

        setTimeout(() => {
          setRedirectOnLogin(true);
        }, 500);
      })
      .catch((error) => {
        setSigninError(error.message);
        setSigninError(error.response.data.message);
        setSigninSuccess(null);
        setLoginLoading(false);
        setSubmitting(false);
      });
  };

  return (
    <>
      {redirectOnLogin && <Redirect to="/home" />}
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Indirizzo email non valido")
            .required("Campo richiesto"),
          password: Yup.string().required("Campo richiesto"),
        })}
        onSubmit={submitCredentials}
      >
        <Form
          className="container fluid text-center"
          style={{ width: "25rem", marginInline: "auto", marginTop: "10rem" }}
        >
          {signinSuccess && (
            <span className="text-success">{signinSuccess}</span>
          )}
          {signinError && (
            <span className="text-danger text-center">{signinError}</span>
          )}
          <div className="text-center container">
            <img
              className="img-fluid"
              src="https://tecnoform.takesover.it/wp-content/uploads/2022/03/Tecnoform-logo2-1-300x62.png"
              alt="Tecnoform"
            ></img>
            <h3>Superscheduler</h3>
          </div>

          <div className="form-gorup">
            <label className="form-label" htmlFor="email">
              Indirizzo Email
            </label>

            <Field className="form-control" name="email" type="email" />

            <ErrorMessage
              name="email"
              render={(msg) => <span className="text-danger">{msg}</span>}
            />
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="password">
              Password
            </label>

            <Field className="form-control" name="password" type="password" />

            <ErrorMessage
              name="password"
              render={(msg) => <span className="text-danger">{msg}</span>}
            />
          </div>

          <GradientButton
            className="btn-block btn btn-primary btn-lg"
            type="submit"
            loading={loginLoading}
          >
            Entra
          </GradientButton>

          <hr />

          <div className="text-center">
            <Link to="/forgot">Password dimenticata?</Link>
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default Login;
