import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";

import { Formik, Field, Form, ErrorMessage } from "formik";
import Modal from "@material-ui/core/Modal";
import * as Yup from "yup";
import GradientButton from "../components/common/GradientButton";
import { publicFetch } from "../utils/fetch";

const ForgotPassword = () => {
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [email, setEmail] = useState("");

  const handleModalClose = () => {};

  const submitEmailToRecover = (values, { setSubmitting }) => {
    //alert(JSON.stringify(values, null, 2));
    // TODO: call a backend API that sends an email to the user
    // with a link to the reset page
    setIsFetching(true);

    publicFetch
      .post(`api/v1/recupero_password`, {
        email: values.email,
      })
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
      .then((data) => {
        setEmail(data.email);
        setIsEmailSent(true);
      })
      .catch((error) => {
        alert(error);
      })
      .finally(() => {
        setIsFetching(false);
        setSubmitting(false);
      });
  };

  return (
    <>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Indirizzo email non valido")
            .required("Campo richiesto"),
        })}
        onSubmit={submitEmailToRecover}
      >
        <Form
          className="container fluid text-center"
          style={{ width: "25rem", marginInline: "auto", marginTop: "10rem" }}
        >
          <div className="text-center container">
            <img
              className="img-fluid"
              src="https://tecnoform.takesover.it/wp-content/uploads/2022/03/Tecnoform-logo2-1-300x62.png"
              alt="Tecnoform"
            ></img>
            <h3>Superscheduler</h3>
          </div>

          <div className="form-gorup">
            <label className="form-label" htmlFor="email">
              Indirizzo Email
            </label>

            <Field className="form-control" name="email" type="email" />

            <ErrorMessage
              name="email"
              render={(msg) => <span className="text-danger">{msg}</span>}
            />
          </div>

          <GradientButton
            className="btn-block btn btn-primary btn-lg"
            type="submit"
            loading={isFetching}
          >
            Reimposta Password
          </GradientButton>

          <hr />

          <div className="text-center">
            <Link to="/login">Hai già un account? Entra qui!</Link>
          </div>
        </Form>
      </Formik>
      <Modal open={isEmailSent} onClose={handleModalClose}>
        <div
          style={{
            background: "white",
            width: "auto",
            marginInline: "auto",
            marginTop: "10rem",
            border: "2px solid #000",
          }}
        >
          <h2>Email inviata con successo a {email}</h2>
          <p>
            Accedi alla tua email, ti è stato inviato un link per reimpostare la
            tua password!
          </p>
        </div>
      </Modal>
    </>
  );
};

export default ForgotPassword;
