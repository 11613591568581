import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

const GradientButton = ({ type, children, className, loading, onClick }) => {
  return (
    <button type={type} className={className} onClick={onClick}>
      {loading ? (
        <span>
          <FontAwesomeIcon icon={faCircleNotch} spin />
          <span>Caricamento...</span>
        </span>
      ) : (
        <span>{children}</span>
      )}
    </button>
  );
};

export default GradientButton;
