import React, { createContext, useState } from "react";
import { useHistory } from "react-router-dom";
import dynamicPolicies from "../utils/dynamicPolicies";
import _ from "lodash";

const AuthContext = createContext();
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const history = useHistory();

  const token = localStorage.getItem("token");
  const userInfo = localStorage.getItem("userInfo");
  const expiresAt = localStorage.getItem("expiresAt");

  const [authState, setAuthState] = useState({
    token,
    expiresAt,
    userInfo: userInfo ? JSON.parse(userInfo) : {},
  });

  const setAuthInfo = ({ access_token, userInfo, expiresAt }) => {
    localStorage.setItem("token", access_token);
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
    localStorage.setItem("expiresAt", new Date(expiresAt));
    setAuthState({
      token: access_token,
      userInfo: userInfo,
      expiresAt: expiresAt,
    });
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("expiresAt");
    setAuthState({});
    history.push("/login");
  };

  const isAuthenticated = () => {
    if (!authState.token /*|| !authState.expiresAt*/) {
      return false;
    }
    // return new Date().getTime() < new Date(authState.expiresAt).getTime();
    return true;
  };

  const getRole = () => {
    return authState.userInfo.role;
  };

  const getId = () => {
    return authState.userInfo.id;
  };

  const getEmail = () => {
    return authState.userInfo.email;
  };

  const getName = () => {
    return authState.userInfo.name;
  };

  const hasPolicy = (action, data) => {
    const permissions = authState.userInfo.policies;

    if (!permissions) return false;

    const staticPermissions = permissions.static;

    if (staticPermissions && staticPermissions.includes(action)) {
      return true;
    }

    const dynamicPermissions = permissions.dynamic;

    if (_.isObject(dynamicPermissions)) {
      const policyName = dynamicPermissions[action];
      const policy = dynamicPolicies[policyName];

      if (!_.isFunction(policy)) {
        return false;
      }

      return policy({ permissions, ...data });
    }

    return false;
  };

  return (
    <Provider
      value={{
        authState,
        setAuthState: (authInfo) => setAuthInfo(authInfo),
        logout,
        isAuthenticated,
        getRole,
        getName,
        getId,
        getEmail,
        hasPolicy,
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
