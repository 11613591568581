import React, { useContext, useEffect, useState } from "react";
import AuthorizedTable from "../common/AuthorizedTable";
import { FetchContext } from "../../context/FetchContext";
import { AuthContext } from "../../context/AuthContext";
import _ from "lodash";
import { isNotEmpty, hasNullValues } from "./utils";

const resource = "manutenzioni";
const title = "Fermi Programmati";
const endpoint = `/api/v1/maintenances`;

const FermiProgrammati = () => {
  const fetchContext = useContext(FetchContext);
  const authContext = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState([
    {
      title: "Utente",
      field: "email",
      type: "string",
      align: "center",
      editable: "never",
    },
    {
      title: "Codice Risorsa",
      field: "cod_risorsa",
      lookup: {},
      align: "center",
      validate: ({ cod_risorsa }) => isNotEmpty(cod_risorsa),
    },
    {
      title: "Data e Ora Inizio",
      field: "tms_inizio_manutenzione",
      type: "datetime",
      align: "center",
      validate: ({ tms_inizio_manutenzione }) =>
        isNotEmpty(tms_inizio_manutenzione),
    },
    {
      title: "Data e Ora Fine",
      field: "tms_fine_manutenzione",
      type: "datetime",
      align: "center",
      validate: ({ tms_fine_manutenzione }) =>
        isNotEmpty(tms_fine_manutenzione),
    },
    {
      title: "Causale",
      field: "category",
      type: "string",
      lookup: {
        programmed: "Manutezione Programmata",
        test: "Test o Prove",
        nowork: "No Lavoro",
        damage: "Guasto/Rottura",
      },
      align: "center",
    },
  ]);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchContext.authAxios
      .get(`${endpoint}`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    fetchContext.authAxios
      .get(`/api/v1/lista_macchine`)
      .then((response) => {
        const newRisorse = {};
        response.data.forEach((elem) => {
          newRisorse[elem.cod_risorsa] = elem.cod_risorsa;
        });
        const newColumns = _.cloneDeep(columns);
        let index = newColumns.findIndex(
          (elem) => elem.field === "cod_risorsa"
        );
        newColumns[index].lookup = newRisorse;
        setColumns(newColumns);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <AuthorizedTable
      resource={resource}
      title={title}
      columns={columns}
      data={data}
      isLoading={isLoading}
      editable={{
        onRowUpdate: (newData, rest) => {
          newData.id_utente = authContext.getId();
          newData.email = authContext.getEmail();
          return new Promise((resolve, reject) => {
            const { tableData, ...oldData } = rest;
            if (_.isEqual(newData, oldData) || hasNullValues(newData)) {
              reject();
            } else {
              fetchContext.authAxios
                .put(`${endpoint}`, {
                  ...newData,
                })
                .then((response) => {
                  if (response.data) return response.data;
                })
                .then((row) => {
                  const dataUpdate = _.cloneDeep(data);
                  dataUpdate[tableData.id] = row;
                  setData([...dataUpdate]);
                  resolve();
                })
                .catch((error) => {
                  console.log(error);
                  reject();
                });
            }
          });
        },
        onRowAdd: (newData) => {
          newData.id_utente = authContext.getId();
          newData.email = authContext.getEmail();
          return new Promise((resolve, reject) => {
            if (hasNullValues(newData)) {
              reject();
            } else {
              fetchContext.authAxios
                .post(`${endpoint}`, {
                  ...newData,
                })
                .then((response) => {
                  if (response.data) {
                    return response.data;
                  }
                })
                .then((row) => {
                  const dataUpdate = _.cloneDeep(data);
                  dataUpdate.push(row);
                  setData([...dataUpdate]);
                  resolve();
                })
                .catch((error) => {
                  console.log(error);
                  reject();
                });
            }
          });
        },
      }}
    />
  );
};

export default FermiProgrammati;
