const categories = [
  {
    id: "1",
    value: "Commerciale",
    tag: "commercial",
    color: "",
  },
  {
    id: "2",
    value: "Manutenzione Da Fare",
    tag: "maintenance",
    color: "",
  },
  {
    id: "3",
    value: "Manutenzione Fatta",
    tag: "maintenance_done",
    color: "",
  },
  {
    id: "4",
    value: "Produzione",
    tag: "production",
    color: "",
  },
  {
    id: "5",
    value: "Tecnica",
    tag: "tech",
    color: "",
  },
  {
    id: "6",
    value: "Officina",
    tag: "workshop",
    color: "",
  },
  {
    id: "7",
    value: "Controllo qualità",
    tag: "quality_control",
    color: "",
  }
];

export default categories;
