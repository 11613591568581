import { FontAwesome } from "@fortawesome/react-fontawesome";

// Api URL
export const API_URL =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "http://localhost:5000"
    : "https://backend.scheduler.tecnoform.cloud.ammagamma.com";

// Routes
export const routes = {
  addUser: "/auth/addUser",
  modifyUsers: "/auth/modifyUsers",
  changePassword: "/auth/changePassword",
  recoverPassword: "/auth/recoverPassword",
};

export var tokens = [];

export let users = [
  {
    email: "admin@admin.com",
    password: "admin",
    name: "Admin",
    role: "admin",
    policies: [],
  },
  {
    email: "user@user.com",
    password: "user",
    name: "User",
    role: "user",
    policies: [],
  },
  {
    email: "ci@o.com",
    password: "ciao",
    name: "Ciao",
    role: "admin",
    policies: [],
  },
];

export const fatecnoform = {
  prefix: "fas",
  iconName: "tecnoform",
  icon: [
    448,
    448,
    [],
    "#000000",
    "M250 475 l0 -65 -30 0 c-16 0 -30 -4 -30 -10 0 -6 45 -10 115 -10 70 0 115 4 115 10 0 6 -13 10 -30 10 l-30 0 0 65 0 65 -55 0 -55 0 0 -65z M290 370 l-115 -6 123 -2 c80 -1 121 2 117 8 -3 6 -7 9 -8 8 -1 -1 -54 -5 -117 -8z M190 330 c0 -5 14 -10 30 -10 l30 0 0 -119 c0 -143 10 -163 91 -180 70 -15 69 -16 69 34 0 25 -4 45 -9 45 -5 0 -17 7 -25 16 -12 12 -16 37 -16 110 l0 94 30 0 c17 0 30 5 30 10 0 6 -45 10 -115 10 -70 0 -115 -4 -115 -10z",
  ],
};
