import React, { lazy, useContext, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { AuthContext, AuthProvider } from "./context/AuthContext";
import { FetchProvider } from "./context/FetchContext";
import { AppProvider } from "./context/AppContext";

import AppShell from "./AppShell";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import AuthDebugger from "./components/AuthDebugger";
import Can from "./components/common/Can";
import DataEntry from "./pages/DataEntry";
import DataEntryLog from "./pages/DataEntryLog";
import RunDetail from "./pages/RunDetail";

const Home = lazy(() => import("./pages/Home"));
const CurrentPlanning = lazy(() => import("./pages/CurrentPlanning"));
const Plan = lazy(() => import("./pages/Plan"));
const RunList = lazy(() => import("./pages/RunList"));
const Warehouse = lazy(() => import("./pages/Warehouse"));
const Profile = lazy(() => import("./pages/Profile"));
const Help = lazy(() => import("./pages/Help"));
const Admin = lazy(() => import("./pages/Admin"));
const Unauthorized = lazy(() => import("./pages/Unauthorized"));
const Registration = lazy(() => import("./pages/Registration"));

const LoadingFallback = () => <div>Loading...</div>;

const UnauthenticatedRoutes = () => (
  <Switch>
    <Route path="/reset/:token">
      <ResetPassword />
    </Route>
    <Route path="/registration/:token">
      <Registration />
    </Route>
    <Route path="/login">
      <Login />
    </Route>
    <Route path="/forgot">
      <ForgotPassword />
    </Route>
    <Route path="/">
      <Login />
    </Route>
  </Switch>
);

const AuthenticatedRoute = ({ children, ...rest }) => {
  const auth = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={() =>
        auth.isAuthenticated() ? (
          <AppShell>{React.cloneElement(children, rest)}</AppShell>
        ) : (
          <Redirect to="/login" />
        )
      }
    ></Route>
  );
};

const AppRoutes = () => {
  return (
    <>
      <Suspense fallback={<LoadingFallback />}>
        <Switch>
          <AuthenticatedRoute path="/home">
            <Can
              perform="kpi:visit"
              yes={() => <Home />}
              no={() => <Unauthorized />}
            />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/current_planning">
            <Can
              perform="current_planning:visit"
              yes={() => <CurrentPlanning />}
              no={() => <Unauthorized />}
            />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/plan">
            <Can
              perform="plan:visit"
              yes={() => <Plan />}
              no={() => <Unauthorized />}
            />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/run_list">
            <Can
              perform="run_list:visit"
              yes={() => <RunList />}
              no={() => <Unauthorized />}
            />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/warehouse">
            <Can
              perform="warehouse:visit"
              yes={() => <Warehouse />}
              no={() => <Unauthorized />}
            />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/profile">
            <Can
              perform="profile:visit"
              yes={() => <Profile />}
              no={() => <Unauthorized />}
            />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/admin">
            <Can
              perform="admin:visit"
              yes={() => <Admin />}
              no={() => <Unauthorized />}
            />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/data_entry">
            <Can
              perform="data_entry:visit"
              yes={() => <DataEntry />}
              no={() => <Unauthorized />}
            />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/data_entry_log">
            <Can
              perform="data_entry_log:visit"
              yes={() => <DataEntryLog />}
              no={() => <Unauthorized />}
            />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/run/:runId">
            <Can
              perform="run_list:visit"
              yes={(props) => {
                return <RunDetail {...props} />;
              }}
              no={() => <Unauthorized />}
            />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/help">
            <Can
              perform="profile:visit"
              yes={() => <Help />}
              no={() => <Unauthorized />}
            />
          </AuthenticatedRoute>
          <UnauthenticatedRoutes />
        </Switch>
      </Suspense>
    </>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <FetchProvider>
        <AppProvider>
          <AppRoutes />
          {/* <AuthDebugger /> */}
        </AppProvider>
      </FetchProvider>
    </AuthProvider>
  );
};

export default App;
