import React, { useContext, useEffect, useState } from "react";
import AuthorizedTable from "../common/AuthorizedTable";
import { FetchContext } from "../../context/FetchContext";
import _, { findIndex } from "lodash";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Chip from "@material-ui/core/Chip";
import { compareLists, compareResources } from "../../utils/functions";

const resource = "articolo_pose_risorse";
const title = "Scelta Risorse";
const endpoint = `/api/v1/articolo_pose_risorse`;

const ArticoloPoseRisorse = () => {
  const fetchContext = useContext(FetchContext);
  const [isLoading, setIsLoading] = useState(true);
  const [occurrencies, setOccurrencies] = useState("..");
  const [columns, setColumns] = useState([
    {
      title: "Codice Articolo",
      field: "cod_parte",
      type: "string",
      align: "center",
      editable: "never",
    },
    {
      title: "Pose",
      field: "val_pose",
      type: "numeric",
      align: "center",
      editable: "never",
    },
    {
      title: "Risorse",
      field: "set_all_resources",
      type: "string",
      lookup: {},
      align: "center",
      render: (rowData) => ResourcesList(rowData),
      editComponent: (props) => ResourcesEdit(props),
      customFilterAndSearch: (term, rowData) => {
        if (term.length === 0) {
          return true;
        }
        for (let i = 0; i < term.length; i++) {
          if (rowData.set_all_resources.includes(term[i])) {
            return true;
          }
        }
        return false;
      },
      customSort: (rowData_a, rowData_b) => {
        return compareLists(
          rowData_a.set_all_resources,
          rowData_b.set_all_resources,
          compareResources
        );
      },
    },
    {
      title: "Compilato",
      field: "flg_touch",
      type: "boolean",
      align: "center",
      defaultSort: "asc",
      editable: "never",
    },
  ]);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchContext.authAxios
      .get(`${endpoint}`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        console.log(data);
        setData(data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    fetchContext.authAxios
      .get(`/api/v1/touch?schema=lkp&table=${resource}`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setOccurrencies(data.occurrencies);
      })
      .catch((error) => {
        console.log(error);
      });

    fetchContext.authAxios
      .get(`/api/v1/lista_macchine`)
      .then((response) => {
        const newRisorse = {};
        response.data.forEach((elem) => {
          newRisorse[elem.cod_risorsa] = elem.cod_risorsa;
        });
        const newColumns = _.cloneDeep(columns);
        let index = newColumns.findIndex(
          (elem) => elem.field === "set_all_resources"
        );
        newColumns[index].lookup = newRisorse;
        setColumns(newColumns);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <AuthorizedTable
        resource={resource}
        title={`${title} (da compilare ${occurrencies})`}
        columns={columns}
        data={data}
        isLoading={isLoading}
        editable={{
          onRowUpdate: (newData, rest) => {
            return new Promise((resolve, reject) => {
              const { tableData, ...oldData } = rest;
              fetchContext.authAxios
                .put(`${endpoint}`, {
                  ...newData,
                })
                .then((response) => {
                  if (response.data) {
                    return response.data;
                  }
                })
                .then((row) => {
                  const dataUpdate = _.cloneDeep(data);
                  dataUpdate[tableData.id] = row;
                  setData([...dataUpdate]);
                  resolve();
                })
                .catch((error) => {
                  console.log(error);
                  reject();
                });
            });
          },
        }}
      />
    </>
  );
};

const ResourcesList = (props) => {
  return (
    <div style={{ margin: "auto" }}>
      {props.set_all_resources.map((elem) => (
        <Chip style={{ margin: 2 }} key={elem} label={elem} />
      ))}
    </div>
  );
};

const ResourcesEdit = (props) => {
  return (
    <>
      <FormControl error={Boolean(props.error)}>
        <Select
          labelId="multiple-resource-label"
          id="multiple-resource"
          multiple
          value={props.value}
          onChange={(event) => props.onChange(event.target.value)}
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => (
            <div>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </div>
          )}
        >
          {Object.keys(props.columnDef.lookup).map((key) => (
            <MenuItem key={key} value={key}>
              {props.columnDef.lookup[key]}
            </MenuItem>
          ))}
        </Select>
        {Boolean(props.helperText) && (
          <FormHelperText>{props.helperText}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default ArticoloPoseRisorse;
