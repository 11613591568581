import React, { useContext, useEffect, useState } from "react";
import AuthorizedTable from "../common/AuthorizedTable";
import { FetchContext } from "../../context/FetchContext";
import _ from "lodash";

const resource = "parametri";
const title = "Parametri";
const endpoint = `/api/v1/parametri`;

const Parametri = () => {
  const fetchContext = useContext(FetchContext);
  const [isLoading, setIsLoading] = useState(true);
  const [columns] = useState([
    {
      title: "Nome Vincolo",
      field: "name_constraint",
      type: "string",
      lookup: {
        max_montaggio_formato_gg: "Massimo numero di montaggi giornalieri",
        max_montaggio_formato_cont: "Massimo numero di montaggi contemporanei",
        max_art_nuovi_gg: "Massimo numero di articoli nuovi giornalieri",
        alert_giorni_bloccati:
          "Numero di giorni lavorativi fissi ad ordini bloccati",
        pesi_fob: "Pesi Funzione Obbiettivo SuperScheduler",
      },
      align: "center",
      editable: "never",
    },
    {
      title: "Codice Reparto",
      field: "cod_reparto",
      type: "string",
      lookup: {
        rep1: "Reparto 1",
        rep3: "Reparto 3",
        None: " ",
      },
      align: "center",
      editable: "never",
    },
    {
      title: "Valore Vincolo",
      field: "val_constraint",
      type: "numeric",
      align: "center",
      validate: ({ val_constraint }) => true,
    },
  ]);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchContext.authAxios
      .get(`${endpoint}`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <AuthorizedTable
      resource={resource}
      title={title}
      columns={columns}
      data={data}
      isLoading={isLoading}
      editable={{
        onRowUpdate: (newData, rest) => {
          return new Promise((resolve, reject) => {
            const { tableData, ...oldData } = rest;
            if (_.isEqual(newData, oldData)) {
              resolve();
            } else {
              fetchContext.authAxios
                .post(`${endpoint}`, {
                  ...newData,
                })
                .then((response) => {
                  if (response.data) {
                    return response.data;
                  }
                })
                .then((row) => {
                  const dataUpdate = _.cloneDeep(data);
                  dataUpdate[tableData.id] = row;
                  setData([...dataUpdate]);
                  resolve();
                })
                .catch((error) => {
                  console.log(error);
                  reject();
                });
            }
          });
        },
      }}
    />
  );
};

export default Parametri;
